import { render, staticRenderFns } from "./ChangeWaregroupIconDialog.vue?vue&type=template&id=4816c163"
import script from "./ChangeWaregroupIconDialog.vue?vue&type=script&lang=js"
export * from "./ChangeWaregroupIconDialog.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports