<template>
    <v-dialog v-model="showDialog" persistent scrollable width="400px">
        <v-card>
            <v-toolbar dense flat>
                <v-toolbar-title>{{ $t('settings.lang_changeName') }}</v-toolbar-title>

                <v-spacer/>

                <v-btn icon @click="closeDialog">
                    <v-icon>close</v-icon>
                </v-btn>
            </v-toolbar>

            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field outlined :label="$t('erp.lang_itemgroup_name')" v-model="name"></v-text-field>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>

            <v-card-actions>
                <v-btn text color="primary" @click="closeDialog">{{$t("generic.lang_cancel")}}</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" :disabled="name.length < 3" @click="saveName">{{ $t("generic.lang_save") }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: "ChangeWaregroupNameDialog",

        props: {
            showDialog: Boolean,
            gridItem: Object
        },

        data() {
            return {
                name: ""
            }
        },

        watch: {
            showDialog(val) {
                if(!val) {
                    this.name = "";
                }
                else {
                    if(this.gridItem !== null) {
                        this.name = this.gridItem.name;
                    }
                }
            }
        },

        methods: {
            closeDialog() {
                this.$emit("close");
            },
            saveName() {
                this.$emit("saveName", this.name);
            },
        }
    }
</script>