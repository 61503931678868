<template>
  <v-dialog
      v-model="dialog"
      width="400"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-list-item v-on="on" v-bind="attrs">
        <v-list-item-icon>
          <v-icon color="error">delete</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            <v-badge
                color="primary"
                content="!"
            >
              {{ $t('generic.lang_delete') }}
            </v-badge>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>

    <v-card>
      <v-card-title>{{ $t('generic.lang_warning') }}</v-card-title>
      <v-card-text class="text-center" style="font-size: 17px;">{{ $t('settings.lang_posLayoutDeleteGridItem') }}</v-card-text>
      <v-card-actions>
        <v-btn color="primary" @click="dialog = false" text>{{ $t('generic.lang_cancel') }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="error" @click="$emit('delete')">{{ $t('generic.lang_delete') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "GridItemDeleteDialog",

  data() {
    return {
      dialog: false
    }
  }
}
</script>