<template>
  <v-dialog v-model="showDialog" max-width="800px" scrollable @click:outside="closeDialog" @keydown="($event.keyCode===27)?closeDialog():''">
    <v-card width="100%">
      <v-toolbar class="text-black heading pt-1 pl-4 pr-1 pb-1" elevation="0" dense>
        <v-toolbar-title>
          {{ $t('erp.lang_ware_create') }}
        </v-toolbar-title>
        <v-spacer/>
        <v-icon @click="closeDialog">mdi-close</v-icon>
      </v-toolbar>
      <v-divider class="ma-0"/>

      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-container>
            <v-row>
              <v-col cols="6">
                <v-text-field
                              :data-layout="KEYBOARD.KEYSETS.NORMAL"
                              :disabled="loading"
                              :label="$t('erp.lang_ware_create_id')"
                              :rules="[v => !!v]"
                              :loading="loadingEAN||loading"
                              dense
                              type="text"
                              @focus="showTouchKeyboard"
                              outlined
                              hide-details
                              append-icon="mdi-barcode"
                              @click:append="GetWareId"
                              v-model="itemEAN"
                />
              </v-col>

              <v-col cols="6">
                <v-text-field
                              :data-layout="KEYBOARD.KEYSETS.NORMAL"
                              :disabled="loading"
                              :label="$t('erp.internal_wareNo')"
                              :loading="loading"
                              dense
                              required
                              type="text"
                              @focus="showTouchKeyboard"
                              outlined
                              hide-details
                              v-model="itemInternalID"
                />
              </v-col>

              <v-col cols="6">
                <v-text-field
                              :data-layout="KEYBOARD.KEYSETS.NORMAL"
                              :disabled="loading"
                              :label="$t('erp.lang_posItemName')"
                              :rules="[v => !!v]"
                              :loading="loading"
                              dense
                              required
                              type="text"
                              @focus="showTouchKeyboard"
                              outlined
                              hide-details
                              v-model="itemName"
                />
              </v-col>

              <v-col cols="6">

                <v-autocomplete
                    :items="itemGroups" item-text="1" item-value="0"
                    :label="$t('generic.lang_waregroup')"
                    outlined
                    :rules="[v => Number(v)>=0]"
                    v-model="itemGroupID"
                    dense
                    hide-details
                    :loading="loading"
                >

                  <template v-slot:append>
                    <v-menu
                        :close-on-content-click="false"
                        :nudge-width="200"
                        id="menu_create"
                        max-width="304"
                        offset-y
                        v-model="menu_add_group"
                    >
                      <template v-slot:activator="{ add }">
                        <v-btn @click="show_newGroupAdd" color="primary"
                               fab class="mt-n1"
                               id="icon-wiregroup-add" x-small
                               v-on="add">
                          <v-icon>add</v-icon>
                        </v-btn>
                      </template>
                      <v-card outlined>
                        <v-row align="center"
                               class="dropdown-menu-header"
                               justify="center">
                          <div class="dropdown-menu-header-inner bg-primary"
                          >
                            <div class="menu-header-image dd-header-bg-1"></div>
                            <div class="menu-header-content text-center pa-3 ma-0"
                            >
                              <div class="text-center"><h5
                                  class="menu-header-title ">
                                {{ $t("erp.lang_waregroupsCreate") }}</h5>
                                <span class="mx-auto text-center">
                                              {{ $t('erp.lang_quickAddItemgroupBodyText') }}
                                            </span>
                              </div>
                            </div>
                          </div>
                        </v-row>
                        <div class="scroll-area-xl pa-2">
                          <v-form class="ma-0 pa-0"
                                  lazy-validation
                                  onsubmit="return false"
                                  ref="wiregroupForm">


                            <v-text-field
                                :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                :label="$t('erp.lang_itemgroup_name')"
                                :rules="[v => !!v]"
                                @focus="showTouchKeyboard"
                                autocomplete="off"
                                dense
                                outlined
                                required
                                v-model="GroupName"
                            ></v-text-field>

                            <v-select
                                :items="itemGroupOrderbonIDSelector"
                                :label="$t('settings.langOrderbonPrinter')"
                                :rules="[v => !!v]"
                                dense item-text="1" item-value="0"
                                outlined
                                v-if="$store.getters['permissions/checkModule'](2)"
                                v-model="itemGroupStdOrderPrinterID"
                            ></v-select>


                            <div>
                              <strong>{{ $t('erp.lang_colourofWaregroup') }}:</strong>
                              <swatches
                                  inline
                                  v-model="itemGroupColorPicker"
                              ></swatches>
                            </div>

                            <v-divider class="pa-0 ma-0"/>
                            <div class="text-center">
                              <button @click="menu_add_group = false"
                                      class="btn btn-danger ma-2">
                                {{ $t('generic.lang_cancel') }}
                              </button>

                              <button :disabled="loadingCreatWireGroup"
                                      @click="createWireGroup"
                                      class="btn btn-primary ma-2">
                                {{ $t('generic.lang_create') }}
                              </button>
                            </div>


                          </v-form>
                        </div>
                      </v-card>
                    </v-menu>
                  </template>
                </v-autocomplete>
              </v-col>

              <v-col cols="6">
                <v-textarea
                              :data-layout="KEYBOARD.KEYSETS.NORMAL"
                              :disabled="loading"
                              :label="$t('erp.lang_ware_create_desc')"
                              v-model="itemDescription"
                              :loading="loading"
                              dense
                              @focus="showTouchKeyboard"
                              outlined
                              rows="3"
                              hide-details
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                              :data-layout="KEYBOARD.KEYSETS.NORMAL"
                              :disabled="loading"
                              :loading="loading"
                              dense
                              :label="$t('erp.lang_netPurchasePricePerUnit')"
                              :rules="[v=>Number(v)>=0]"
                              type="number"
                              @focus="showTouchKeyboard"
                              outlined
                              hide-details
                              v-model="itemPurchasePrice"
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                    :data-layout="KEYBOARD.KEYSETS.NORMAL"
                    :disabled="loading"
                    :label="$t('erp.lang_ware_create_sprice')+' '+$t('erp.lang_gross')"
                    :loading="loading"
                    dense
                    :rules="[v=>v !==null && Number(v)>=0]"
                    type="number"
                    @focus="showTouchKeyboard"
                    outlined
                    hide-details
                    v-model="itemSellPrice"
                />
              </v-col>
              <v-col cols="6">

                <v-select
                    :items="filteredUnits" item-text="1" item-value="0"
                    :label="$t('erp.lang_purchaseUnit')"
                    outlined
                    :rules="[v => Number(v)>=0]"
                    v-model="itemPurchaseUnit"
                    dense
                    hide-details
                    :loading="loading"
                >
                </v-select>
              </v-col>

              <v-col cols="6">
                <v-select
                    :items="filteredUnits" item-text="1" item-value="0"
                    :label="$t('erp.lang_salesunit')"
                    :rules="[v => Number(v)>=0]"
                    outlined
                    v-model="itemUnit"
                    dense
                    hide-details
                    :loading="loading"
                >
                </v-select>
              </v-col>
              <v-col cols="6">
                <v-select
                    :items="tax"
                    :label="$t('erp.lang_tax_value')"
                    :rules="[v => !!v]"
                    item-text="name"
                    outlined
                    v-model="itemTax"
                    dense
                    hide-details
                    return-object
                    @change="log($event)"
                    :loading="loading"
                >
                </v-select>
              </v-col>

              <v-col cols="6">
                <v-checkbox
                    :hide-details=true
                    :label="$t('settings.lang_settings_activ')"
                    autocomplete="off" :true-value="1" :false-value="0"
                    style="margin-top:0px !important;"
                    v-model="itemIsActive"
                ></v-checkbox>
              </v-col>
            </v-row>

          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions >
        <v-container class="ma-0 pa-0">
          <v-row no-gutters>
            <v-col cols="6" >
              <v-btn color="error" text @click="closeDialog">{{$t('generic.lang_cancel')}}</v-btn>
            </v-col>
            <v-col cols="6" align="right">
              <v-btn @click="addItem" :disabled="!valid"
                  color="primary" v-if="true" :loading="loading"
                  >{{$t('generic.lang_add')}}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>

      </v-card-actions>
      <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard" style="z-index: 299 !important;">
        <vue-touch-keyboard v-if="touchKeyboard.visible" id="onScreenKeyboard"
                            :accept="hideTouchKeyboard"
                            :cancel="hideTouchKeyboard"
                            :defaultKeySet="touchKeyboard.keySet"
                            :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                            :options="touchKeyboard.options" class="internalWidthExpanded"/>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";
import Swatches from 'vue-swatches';
import "vue-swatches/dist/vue-swatches.css"
import KeyboardMixIns from "../../mixins/KeyboardMixIns";

export default {
  name: "CreateItemDialog",
  components:{
    Swatches
  },
  mixins: [KeyboardMixIns],
  props:{
    showDialog:Boolean,
  },
  data(){
    return{
      loading:false,
      loadingEAN:false,
      valid:false,
      units:[],
      tax:[],
      itemGroups:[],
      itemEAN:null,
      itemInternalID:null,
      itemName:null,
      itemGroupID:null,
      itemDescription:null,
      itemPurchasePrice:null,
      itemSellPrice:null,
      itemUnit:null,
      itemPurchaseUnit:null,
      itemIsActive:1,
      itemTax:{},
      itemGroupOrderbonIDSelector:[],
      itemGroupStdOrderPrinterID:null,
      itemGroupColorPicker:null,
      GroupName:null,
      menu_add_group:false,
      loadingCreatWireGroup:false,
    }
  },
  computed:{
    filteredUnits(){
      if(!this.units || !this.units.length){
        return [];
      }
      let filtered=[];
      filtered=this.units.map( unit => {
        if(!unit && unit[1].length === 0)
          return;

        if(unit[1].includes("lang_"))
          unit[1] = this.$t('erp.'+unit[1]);

        return unit;
      })
      return filtered;
    }

  },
  methods:{
    log(data){

    },
    closeDialog(){
      this.$emit('close');
    },
    GetWareId() {
      this.loadingEAN=true;
      this.axios.post(ENDPOINTS.ERP.INVENTORYITEM.EAN.CREATE).then((res) => {
        if (res.status === 200) {
          this.itemEAN = res.data && res.data != null && res.data.length > 0 ? res.data[0] : this.itemEAN;
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      }).finally(()=>{
        this.loadingEAN=false;
      });
    },

    getData() {
      this.axios.post(ENDPOINTS.ERP.ITEM.GET, {}).then((res) => {
        if (res.status === 200) {
          this.itemGroups = [];
          this.itemGroups = res.data.waregroups;
          this.itemGroupOrderbonIDSelector = res.data.orderbonPrinters;
          res.data.tax.forEach((row) => {
            this.tax.push({name: row[2], id: parseInt(row[0]),value:Number(row[1])});
          });
          //SET DEFAULT TAX
          if(this.tax){
            this.itemTax=this.tax[0]
          }
          this.units=res.data.units

        }
      }).catch(() => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      });
    },
    addItem(){
      if (!this.$refs.form.validate()) {
        return;
      }
      this.loading=true;
      this.axios.post(ENDPOINTS.ERP.ITEM.CREATE, {
        ware_ID: this.itemEAN,
        createItemWareGroupId: this.itemGroupID,
        ware_internalNo: this.itemInternalID,
        ware_name: this.itemName,
        ware_buyprice: this.itemPurchasePrice,
        wareSellingPrice: this.itemSellPrice,
        createItemTaxId: this.itemTax.id,
        createItemDescr: this.itemDescription,
        unitID:parseInt(Number(this.itemUnit)),
        itemActive:1,
        unitofPurchaseID:parseInt(Number(this.itemPurchaseUnit)),
      }).then((res) => {
        if (res.data.status === 'SUCCESS') {
          Events.$emit("showSnackbar", {
            message: this.$t('erp.lang_itemSuccessfulAdded'),
            color: "success"
          });

          let item={
            id:parseInt(Number(res.data.itemID)),
            name:this.itemName,
            description:this.itemDescription,
            sellPrice:this.itemSellPrice,
            taxValue:Number(this.itemTax.value),
            ean:this.itemEAN,
            internalWareID:this.itemInternalID,
            itemgroupID:this.itemGroupID,
            openPriceInput:null,
            openWeightInput:null,
            depositgroupID:null,
            unitID:this.itemUnit,
            unitofPurchaseID:this.itemPurchaseUnit,
            mealSizes:[],
            takeAwayTaxing:null,
            producer:null,
            supplier:null,
            payOutItem:null,
            backgroundColor:null,
            active:1,
            hasMHD:null,
            hasChargenNo:null,
            size:null,
            color:null,
            variationSize:null,
            variationColor:null,
            variationColorHex:null,
            variations:[],
            isVariation:null,
            zusatzstoffe:null,
            allergene:null,
            ageVerification:null,
            extras:[],
            orderbonPrinterID:null,
          }
          this.$emit('selectItem',item);
          this.$refs.form.reset()
          this.$emit('close')
        } else if (res.data.status === 'CHECK ERROR' || res.data.status === 'duplicateItem') {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_eanCheckFailed'),
            color: "error"
          });
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred')+' '+err.message,
          color: "error"
        });
      }).finally(()=>{
        this.loading=false;
      });
    },
    createWireGroup() {
      if (!this.$refs.wiregroupForm.validate()) return;
      let form = {};
      form.wareGroupName = this.GroupName;
      form.orderBonPrinterID = this.itemGroupStdOrderPrinterID;
      form.createWaregroupColor = this.itemGroupColorPicker;
      this.loadingCreatWireGroup = true;

      this.axios.post(ENDPOINTS.ERP.ITEMGROUP.CREATE, form).then((res) => {
        if (res.data.status === 'SUCCESS') {
          Events.$emit("showSnackbar", {
            message: this.$t('erp.lang_itemgroupCreated'),
            color: "success"
          });

          this.itemGroups.push([
            parseInt(res.data.id),
            this.GroupName
          ]);

          if (this.itemGroups && this.itemGroups.length > 0) {
            this.itemGroupID = parseInt(res.data.id)
          }
          this.menu_add_group = false;
          this.$refs.wiregroupForm.reset();


        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.loadingCreatWireGroup = false;
      });
    },
    show_newGroupAdd() {
      this.menu_add_group = true;
    },
  },
  mounted() {
    this.getData()
  },
}
</script>

<style scoped>

</style>