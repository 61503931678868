<template>
  <v-dialog v-model="showDialog" persistent scrollable max-width="700px">
    <v-card>
      <v-toolbar dense flat>
        <v-toolbar-title>{{ $t('generic.lang_addItemgroup') }}</v-toolbar-title>

        <v-spacer/>

        <v-btn icon @click="closeDialog">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-tabs>
        <v-tab>{{ $t('erp.lang_nav_waregroups') }}</v-tab>
        <v-tab>{{ $t('erp.lang_waregroupsCreate') }}</v-tab>

        <v-tab-item>
          <v-card-text style="overflow-x: auto; max-height: 500px; padding: 0;">
            <v-list>
              <v-list-item @click="selectWaregroup(waregroup)" v-for="waregroup in filteredItemGroups"
                           :key="waregroup.id">
                <v-list-item-content>
                  <v-list-item-title>{{ waregroup.name }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-tab-item>

        <v-tab-item>
          <v-card-text>
            <v-text-field @focus="showTouchKeyboard"
                          :data-layout="KEYBOARD.KEYSETS.NORMAL"
                          dense outlined
                          v-model="virtWaregroupName"
                          :label="$t('settings.lang_virtualItemgroup')"></v-text-field>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="createVirtWaregroup">{{ $t('generic.lang_save') }}</v-btn>
          </v-card-actions>
        </v-tab-item>
      </v-tabs>


      <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
        <vue-touch-keyboard id="onScreenKeyboard"
                            class="internalWidth"
                            :options="touchKeyboard.options"
                            :defaultKeySet="touchKeyboard.keySet"
                            v-if="touchKeyboard.visible"
                            :layout="touchKeyboard.layout"
                            :cancel="hidetouchKeyboard"
                            :accept="hidetouchKeyboard"
                            :input="touchKeyboard.input"/>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import KeyboardMixIns from "../../../../../mixins/KeyboardMixIns";

export default {
  name: "WaregroupsDialog",

  props: {
    showDialog: Boolean,
    isForDelivery: Boolean,
  },
  mixins: [KeyboardMixIns],
  data() {
    return {
      virtWaregroupName: "",
      filteredItemGroups: [],
    }
  },
  watch: {
    async showDialog(val) {
      if (val) {
        this.filteredItemGroups = await this.$store.dispatch("itemgroups/getItemgroups");
      }
    }
  },

  methods: {
    closeDialog() {
      this.$emit("close");
    },
    selectWaregroup(waregroup) {
      this.$emit("selectWaregroup", waregroup);
    },
    createVirtWaregroup() {
      this.$emit("selectWaregroup", {
        name: this.virtWaregroupName
      }, true);

      this.virtWaregroupName = "";
    }
  }
}
</script>
