import {mapGetters, mapState} from "vuex";
import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";
import randomString from "randomstring";
import {roundNumber} from "@/plugins/round";
import {format} from "mathjs";
import { stubTrue } from "lodash";


export default {
    data() {
        return {
            invoiceRef: '',
            invoiceDate: '',
            invoiceDue: '',
            terms: '',
            freeText: '',
            paymentMethod: null,
            showCreateCustomerDialog: false,
            showShippingContactDialog: false,
            showCreateItemDialog: false,
            customer: null,
            shippingContact: null,
            showValidation: false,
            discount: 0,
            headerTemplates: [],
            deliveryTermsTextTemplates: [],
            headerTemplate: null,
            deliveryTermsTextTemplate: null,
            headerText: null,
            conditionsTemplates: [],
            conditionsTemplate: null,
            conditions: null,
            tax: [],
            units: [],
            currentItem: null,
            invoiceItems: [],
            selectedUser: null,
            loadingSystem: false,
            loadingTextTemplate: false,
            system: null,
            items: [],
            awaitingSearch: null,
            loading: false,
            billed: false,
            performanceDate: '',
        }
    },
    computed: {
        ...mapState([
            'settings',
            'payments',
            'pos'
        ]),
        ...mapGetters({
            depositgroups:'deposit/depositgroups',
        }),
        isHybrid(){
            return  process.env.VUE_APP_HYBRID === "true" || this.$store.getters["permissions/checkHybridApp"]
        },
        dataTableHeaders() {
            return [
                {
                    text: this.$t("generic.lang_numberShort"),
                    value: "no",
                    width: 50,
                },
                {
                    text: this.$t("erp.lang_item"),
                    value: "name",
                    width: 200,
                },
                {
                    text: this.$t("generic.lang_description"),
                    value: "description",
                },
                {
                    text: this.$t("erp.lang_QTY"),
                    value: "qty",
                    width: 120,
                },
                {
                    text: this.$t("erp.lang_ware_netPerUnit"),
                    value: "price",
                    width: 120,
                },
                {
                    text: this.$t('erp.lang_ware_create_sprice') + ' ' + this.$t('erp.lang_gross'),
                    value: "sellPrice",
                    width: 120,
                },
                {
                    text: this.$t("generic.lang_discount") + " in %",
                    value: "discount",
                    width: 100,
                },
                {
                    text: this.$t("erp.lang_mwst") + " in %",
                    value: "tax",
                    width: 100,
                },
                {
                    text: this.$t("generic.lang_total"),
                    value: "total",
                    width: 130,
                },
                {
                    text: "",
                    value: "crud",
                },
            ]
        },
        roundingPlaces() {
            return Number(this.settings.settings.roundingPlaces) && Number(this.settings.settings.roundingPlaces) > 0 ? Number(this.settings.settings.roundingPlaces) : 2;
        },
        subtotal() {
            let total = 0;

            this.validItems.forEach(item => {
                let price = Number(item.amount) * Number(item.price);
                total += price;
            });

            return total;
        },
        total() {
            let total = 0;

            this.validItems.forEach(item => {
                this.calculateTotal(item);
                total += Number(item.total);
            })

            return total;
        },
        taxRate() {
            let tax = [];
            let itemCounter = 0
            if (this.invoiceItems && this.invoiceItems.length > 0) {
                this.invoiceItems.forEach((item) => {
                    if (Number(item.id)) {
                        ++itemCounter;
                        if (Number(item.taxValue) !== 0) {
                            let existedTax = tax.indexOf(tax.find(el => {
                                return (Number(el.rate) === Number(item.taxValue))
                            }))
                            let subTotal = item.price;
                            subTotal = (Number(item.taxValue) / 100) * (subTotal - (subTotal * (item.discount / 100)));
                            subTotal *= Number(item.amount);
                            if (existedTax >= 0) {
                                tax[existedTax].amount += subTotal;
                            } else {
                                tax.push({
                                    rate: Number(item.taxValue),
                                    amount: subTotal
                                });
                            }
                        }
                    }
                });
                if (itemCounter === 0) {
                    tax = [
                        {
                            rate: 0,
                            amount: 0,
                        }
                    ]
                }
            } else {
                tax = [
                    {
                        rate: 0,
                        amount: 0,
                    }
                ]
            }
            return tax;
        },
        totalTax() {
            let total = 0;
            this.taxRate.forEach((tax) => {
                total += tax.amount;
            });
            return total;
        },
        discountRate() {
            let discount = [];
            let itemCounter = 0;
            if (this.invoiceItems && this.invoiceItems.length > 0) {
                this.invoiceItems.forEach((item) => {
                    if (Number(item.id)) {
                        if (Number(item.discount) !== 0) {
                            ++itemCounter;
                            let existedDiscount = discount.indexOf(discount.find((el) => {
                                return Number(el.rate) === Number(item.discount)
                            }));
                            let subTotal = item.price * Number(item.amount);
                            subTotal = subTotal * (Number(item.discount) / 100)
                            if (existedDiscount >= 0) {
                                discount[existedDiscount].amount -= subTotal
                            } else {
                                discount.push(
                                    {
                                        rate: Number(item.discount),
                                        amount: -subTotal
                                    }
                                )
                            }
                        }
                    }
                });
                if (itemCounter === 0) {
                    discount = [
                        {
                            rate: 0,
                            amount: 0,
                        }
                    ]
                }
            } else {
                discount = [
                    {
                        rate: 0,
                        amount: 0,
                    },
                ];
            }
            return discount;
        },
        totalDiscount() {
            let total = 0;
            this.discountRate.forEach(discount => {
                total -= discount.amount;
            });
            return total;
        },
        totalAfterDiscount() {
            return this.subtotal - this.totalDiscount;
        },
        filteredItems() {
            return _.orderBy(this.items.filter(item => {
                return item.active === 1;
            }), "name")
        },
        filteredPayments() {
            return _.orderBy(this.payments.payments.filter(payment => {
                return payment.paymentID !== 7
            }), 'paymentName')
        },
        validItems() {
            if (!this.invoiceItems) {
                return [];
            }
            return this.invoiceItems.filter(item => item.id !== 0);
        },
        isValid() {
            return this.validItems.length>0 && this.paymentMethod;
        },
        isValidQuotationDeliveryNote() {
            return this.validItems.length>0;
        },
    },
    methods: {
        itemsFilter(item, queryText) {
            return (
                item.name?.toLowerCase().includes(queryText?.toLowerCase()) ||
                item.ean?.toLowerCase().includes(queryText?.toLowerCase())
            );
        },
        searchItem(event, item) {
            clearTimeout(this.awaitingSearch);
            this.awaitingSearch = setTimeout(() => {
                this.items = [];
                item.loading = true;
                this.axios
                    .post(ENDPOINTS.ERP.ITEM.SEARCH2, {query: event.target.value})
                    .then((res) => {
                        this.items = res.data;
                    })
                    .catch((err) => {
                    })
                    .finally(() => {
                        item.loading = false;
                    });
            }, 500);
        },

        discountInput(item) {
            if (Number(this.discount) > 0) {
                this.resetDiscount();
            }

            if (Number(item.discount) > 100) {
                item.discount = 100;
            }

            if (Number(item.discount) < 0) {
                item.discount = 0;
            }
            this.calculateTotal(item);
        },
        taxInput(item) {
            if (Number(item.taxValue) > 100) {
                item.taxValue = 100;
            }
            if (Number(item.taxValue) < 0) {
                item.taxValue = 0;
            }

            if (this.settings.settings.factureGrossPrice === '1') {
                item.price = this.getItemNetPrice(item);
            } else {
                item.sellPrice = this.getItemSellPrice(item);
            }
            this.calculateTotal(item);
        },
        priceInput(item, type) {
            if (type === 'net') {

                if (item.price === "" && Number(item.price) < 0 && !!item.price) {
                    item.price = this.getItemNetPrice(item);
                }
                item.sellPrice = this.getItemSellPrice(item);

            } else {
                if (item.sellPrice === "" && Number(item.sellPrice) < 0 && !!item.sellPrice) {
                    item.sellPrice = this.getItemSellPrice(item);
                }
                item.price = this.getItemNetPrice(item)
            }
            this.calculateTotal(item);
        },
        discountAll(event) {
            if (Number(event) > 100) {
                this.discount = 100;
            }

            if (!Number(event)) {
                this.discount = 0;
            }

            this.invoiceItems.forEach((item) => {
                item.discount = this.discount;
                this.calculateTotal(item);
            });
        },
        resetDiscount() {
            this.discount = 0;
            this.invoiceItems.forEach((item) => {
                item.discount = 0;
                this.calculateTotal(item);
            });
        },
        getItemSellPrice(item) {
            let total = 0;
            if (item.price === "" && Number(item.price) < 0) return 0;

            total = Number(item.price);
            total = roundNumber(Number(total) + Number(total) * (Number(item.taxValue) / 100), this.roundingPlaces);
            return total;
        },
        getItemNetPrice(item) {
            let total = 0;
            if (item.sellPrice === "" && Number(item.sellPrice) < 0) return 0;
            total = roundNumber(((Number(item.sellPrice) / (1 + Number(item.taxValue) / 100)) * 100) / 100, this.roundingPlaces);

            return total;
        },
        calculateTotal(item) {

            if (Number(item.amount) < 0)
                item.amount = 0;

            if ((item.price === "" && Number(item.price) < 0) && (item.amount === "" && Number(item.amount) < 0)) return;
            let total = 0;
            total = this.getItemNetPrice(item);
            total = total - total * (Number(item.discount) / 100);
            total = total + total * (Number(item.taxValue) / 100);
            total = roundNumber(total, this.roundingPlaces);
            total += item.depositgroup ? item.depositgroup.priceTotal : 0;
            total = Number(total) * Number(item.amount);
            item.total = total;
        },

        addItem() {
            this.invoiceItems.push({
                no: this.invoiceItems.length,
                amount: 1,
                randomPositionID: randomString.generate(15),
                id: 0,
                name: null,
                ean: null,
                price: 0.0,
                sellPrice: 0.0,
                originalSellPrice: 0.0,
                selectedExtras: [],
                description: null,
                discount: 0,
                mealSizeID: null,
                internalWareID: null,
                itemgroupID: null,
                depositgroupID: null,
                isPriceChanged: null,
                isVoid: null,
                needsVoidPrint: null,
                voidReason: null,
                freeText: null,
                taxValue: null,
                takeAwayTaxing: null,
                isVariation: null,
                serialNo: null,
                isMenuItem: 0,
                menuTaxRules: [],
                payOutItem: null,
                additionalStatisticUUIDs: [],
                individualBookingNo: null,
                depositgroup: null,
                total:0,
                loading: false,
            });
        },
        clearItem(item) {
            item.amount = 1;
            item.id = 0;
            item.name = null;
            item.ean = null;
            item.price = 0.0;
            item.sellPrice = 0.0;
            item.originalSellPrice = 0.0;
            item.selectedExtras = [];
            item.description = null;
            item.discount = 0;
            item.mealSizeID = null;
            item.internalWareID = null;
            item.itemgroupID = null;
            item.depositgroupID = null;
            item.isPriceChanged = null;
            item.isVoid = null;
            item.needsVoidPrint = null;
            item.voidReason = null;
            item.freeText = null;
            item.taxValue = 0;
            item.takeAwayTaxing = null;
            item.isVariation = null;
            item.serialNo = null;
            item.isMenuItem = 0;
            item.menuTaxRules = [];
            item.payOutItem = null;
            item.additionalStatisticUUIDs = [];
            item.individualBookingNo = null;
            item.depositgroup = null;
            item.loading = false;
            item.originalItem = null;
            item.total = 0;
        },
        deleteItem(itemToDelete) {
            this.invoiceItems.splice(itemToDelete.no, 1);

            this.invoiceItems.forEach((item, index) => {
                item.no = index;
            });
        },
        showAddItemDialog(item) {
            // DON'T SHOW CREATE ITEM DIALOG IF HYBRID IS OFFLINE
            if(this.$store.getters['permissions/checkHybridState'])
                return;

            this.showCreateItemDialog = true;
            this.currentItem = item;
        },
        setItem(item) {
            this.selectItem(item, this.currentItem);
        },
        getUnit(id) {
            if (id) {
                let unit = this.units.find((unit) => unit.id === id);
                return unit ? unit.unit_sign : "";
            }
            return "";
        },
        async setShippingContactData(shippingContact) {
            this.shippingContact = shippingContact;
        },
        //CUSTOMER AND CUSTOMER GROUP HANDLING
        async setCustomerData(customer, handleGroup = false) {
            let selected = customer;

            if (this.validItems) this.loading = true;

            // set other data for customer
            this.axios
                .post(ENDPOINTS.CUSTOMERS.CUSTOMER.GETEDIT, {
                    customerID: selected.id,
                })
                .then(async (res) => {
                    if (res.data.success) {
                        selected.itemgroupDiscount = res.data.customer.itemgroupDiscount;
                        selected.group = res.data.customer.group;
                        this.customer = selected;
                        if (!handleGroup)
                            return;

                        await this.setItemsForCustomerGroup().then(() => {
                            this.loading = false;
                        });
                    } else {
                        Events.$emit("showSnackbar", {
                            color: "error",
                            message: res.data.msg,
                        });
                        return;
                    }
                })
                .catch((err) => {
                    Events.$emit("showSnackbar", {
                        color: "error",
                        message: err.message,
                    });
                    return;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        async setItemsForCustomerGroup() {
            if (this.validItems && this.validItems.length > 0) {
                this.validItems.forEach((item) => {
                    let discount = 0;

                    // ITEMGROUP DISCOUNT
                    if (this.customer.itemgroupDiscount !== null) {
                        if (this.customer.itemgroupDiscount.hasOwnProperty(item.originalItem.itemgroupID)) {
                            discount = this.customer.itemgroupDiscount[item.originalItem.itemgroupID];
                        }
                    }

                    // WARE PRICE
                    if (this.customer.group !== null) {
                        if (this.customer.group.type === 1) {
                            this.axios
                                .post(ENDPOINTS.POS.CUSTOMERGROUPITEMPRICE, {
                                    customerGroup: this.customer.group.id,
                                    itemId: item.id,
                                })
                                .then((res) => {
                                    if (res.data.STATUS) {
                                        item.sellPrice = Number(res.data.price);
                                        item.price =
                                            ((Number(res.data.price) /
                                                    (1 + Number(item.taxValue) / 100)) *
                                                100) /
                                            100;
                                        item.price = format(item.price, {precision: 14});
                                        item.price = roundNumber(item.price, this.roundingPlaces);
                                    }
                                });
                        }
                    }
                    item.discount = discount;
                });
            }
        },
        async selectItem(selectedItem, item) {
            if (!selectedItem) return;

            if (this.settings.settings.allowNegativeStock != 1) {
                this.axios
                    .post(ENDPOINTS.ERP.ITEM.WAREINFO.GET, {
                        wareID: item.id,
                    }).then(res => {
                        if (res.data && res.data.length > 0) {
                            if (Number(res.data[1]) <= 0) {
                                Events.$emit("showSnackbar", {
                                    message: 'there is not item in storage',
                                    color: "warning"
                                })
                                return;
                            }
                        } else {
                            Events.$emit("showSnackbar", {
                                message: 'there is not item in storage',
                                color: "warning"
                            });
                            return;
                        }
                    }).catch(err => {
                        Events.$emit("showSnackbar", {
                            message: 'item storage : ' + err.message,
                            color: "error"
                        });
                    })
            }

            //CHECK CUSTOMER DISCOUNT OR ITEM PRICE
            let discount = Number(this.discount) ? Number(this.discount) : 0;
            let deposit = null;
            if(selectedItem.depositgroupID > 0){
                deposit = this.depositgroups.find((depositGroup) => {
                    return depositGroup.id === selectedItem.depositgroupID;
                });
                if(deposit)
                    item.depositgroup = deposit;
            }

            item.taxValue = selectedItem.taxValue;
            item.price = ((Number(selectedItem.sellPrice) / (1 + Number(selectedItem.taxValue) / 100)) * 100) / 100;
            item.price = format(item.price, {precision: 14});
            item.price = roundNumber(item.price, this.roundingPlaces);

            //APPLYING THE ITEMS SELL PRICE before getting the new price
            item.sellPrice = this.getItemSellPrice(item);

            if (this.customer) {
                // ITEMGROUP DISCOUNT
                if (this.customer.itemgroupDiscount) {
                    if (this.customer.itemgroupDiscount.hasOwnProperty(selectedItem.itemgroupID)) {
                        discount = this.customer.itemgroupDiscount[selectedItem.itemgroupID];
                    }
                }

                // WARE PRICE
                if (this.customer.group)
                    if (this.customer.group.type === 1) {
                        await this.axios.post(ENDPOINTS.POS.CUSTOMERGROUPITEMPRICE, {
                            customerGroup: this.customer.group.id,
                            itemId: selectedItem.id,
                        }).then((res) => {
                            if (res.data.STATUS) {
                                item.sellPrice = Number(res.data.price);
                                item.price = ((Number(res.data.price) / (1 + Number(item.taxValue) / 100)) * 100) / 100;
                                item.price = format(item.price, {precision: 14});
                                item.price = roundNumber(item.price, this.roundingPlaces);
                            }
                        });
                    }
            }

            item.id = selectedItem.id;
            item.ean = selectedItem.ean;
            item.name = selectedItem.name;
            item.description = selectedItem.description;
            item.amount = 1;
            item.unit = this.getUnit(selectedItem.unitID);
            item.originalSellPrice = selectedItem.sellPrice;
            item.selectedExtras = [];
            item.mealSizeID = 0;
            item.itemgroupID = selectedItem.itemgroupID;
            item.depositgroupID = selectedItem.depositgroupID;
            item.isVoid = false;
            item.needsVoidPrint = false;
            item.voidReason = "";
            item.freeText = null;
            item.isPriceChanged = (item.sellPrice !== item.originalSellPrice);
            item.discount = discount;
            item.takeAwayTaxing = selectedItem.takeAwayTaxing;
            item.taxValue = selectedItem.taxValue;
            item.isVariation = selectedItem.isVariation;
            item.serialNo = null;
            item.isMenuItem = selectedItem.isMenuItem;
            item.menuTaxRules = selectedItem.menuTaxRules;
            item.taxValue = selectedItem.taxValue;
            item.payOutItem = selectedItem.payOutItem;
            item.additionalStatisticUUIDs = [];
            item.individualBookingNo = null;
            item.originalItem = selectedItem;
            this.items = [];

            this.calculateTotal(item)
        },

        // GET SYSTEM DATA
        getTaxes() {
            this.axios
                .post(ENDPOINTS.ERP.TAXSETUP.GETALL, {})
                .then((res) => {
                    if (res.data.STATUS === "SUCCESS") {
                        this.tax = res.data.tax.map((tax) => {
                            return {
                                name: tax.name,
                                value: Number(tax.value),
                            };
                        });
                    } else {
                        Events.$emit("showSnackbar", {
                            message: res.data.msg || res.data.status,
                            color: "error",
                        });
                    }
                })
                .catch((err) => {
                    Events.$emit("showSnackbar", {
                        message: err.message,
                        color: "error",
                    });
                });
        },
        getUnits() {
            this.axios
                .post(ENDPOINTS.ERP.UNITS.GET)
                .then((res) => {
                    if (res.data.STATUS === "SUCCESS") {
                        this.units = res.data.units;
                    } else {
                        Events.$emit("showSnackbar", {
                            message: res.data.msg || res.data.STATUS,
                            color: "error",
                        });
                    }
                })
                .catch((err) => {
                    Events.$emit("showSnackbar", {
                        message: this.$t("generic.lang_errorOccurred") + ": " + err.message,
                        color: "error",
                    });
                });
        },
        getTextTemplates() {
            this.loadingTextTemplate = true;

            this.axios.post(ENDPOINTS.SETTINGS.BASIC.SYSTEMSETTINGS.RECEIPTA4.TEXTTEMPLATES.GETALL)
                .then((res) => {
                    this.conditionsTemplates = [];
                    this.headerTemplates = [];
                    res.data.data.forEach((tmpl) => {
                        if (tmpl.type === 0) {
                            this.headerTemplates.push(tmpl);
                        } else {
                            this.conditionsTemplates.push(tmpl);
                        }
                    });
                }).catch((err) => {
                Events.$emit("showSnackbar", {
                    message: err.message,
                    color: "error",
                });
            })
                .finally(() => {
                    this.loadingTextTemplate = false;
                });
        },
        getDeliveryTerms() {
            this.loadingTextTemplate = true;

            this.axios.post(ENDPOINTS.SETTINGS.BASIC.SYSTEMSETTINGS.RECEIPTA4.DELIVERYTERMS.GETALL)
                .then((res) => {
                    if(res.data.status){
                        this.deliveryTermsTextTemplates = res.data.data
                    }else{
                        Events.$emit("showSnackbar", {
                            message: this.$t('generic.lang_errorOccurred'),
                            color: "error",
                        });
                    }
                }).catch((err) => {
                Events.$emit("showSnackbar", {
                    message: err.message,
                    color: "error",
                });
            })
                .finally(() => {
                    this.loadingTextTemplate = false;
                });
        },
        getSystemInfo() {
            this.loadingSystem = true;
            this.axios.post(ENDPOINTS.BILLING.INVOICEDATA, {}).then((res) => {
                this.system = res.data.formfillData.textFields;
            }).catch((err) => {
                Events.$emit("showSnackbar", {
                    message: err.message,
                    color: "error",
                });
            })
                .finally(() => {
                    this.loadingSystem = false;
                });
        },
    }
}
