<template>
  <v-row align="center" class="transparent ma-0 mx-0 " >
    <v-col v-for="i in 9" :key="i" class="text-center" style="padding: 2px;" cols="4">
      <v-card :id="'btn_' + i" :class="(buttonStyle===null)?'pa-3':'pa-'+buttonStyle.padding"
             :color="$vuetify.theme.dark? '#424242' : 'white'" elevation="1" :height="(buttonStyle===null)?42:buttonStyle.height"
             @click="button(i)">
        <v-card-text class="text-muted fsize-2 pa-0">
          {{ i }}
        </v-card-text>
      </v-card>
    </v-col>

    <v-col v-if="!doubleZero"  class="text-center " style="padding: 2px;" cols="4" >
      <v-card :color="$vuetify.theme.dark? '#424242' : 'white'" elevation="1" :height="(buttonStyle===null)?42:buttonStyle.height"
              :class="(buttonStyle===null)?'pa-3':'pa-'+buttonStyle.padding">
        <v-card-text class="text-muted fsize-2 pa-0">
          <font-awesome-icon
              :icon="['fal', 'comment-alt-dollar']"
              class="text-muted font-size-xlg"
          />
        </v-card-text>
      </v-card>
    </v-col>

    <v-col v-else class="text-center" style="padding: 2px;" cols="4">
      <v-card :color="$vuetify.theme.dark? '#424242' : 'white'" elevation="1" :class="(buttonStyle===null)?'pa-3':'pa-'+buttonStyle.padding"
              :height="(buttonStyle===null)?42:buttonStyle.height"  @click="button('00')">
        <v-card-text class="text-muted fsize-2 pa-0 ">
          00
        </v-card-text>
      </v-card>
    </v-col>

    <v-col class="text-center" style="padding: 2px;" cols="4">
      <v-card :color="$vuetify.theme.dark? '#424242' : 'white'" elevation="1" :class="(buttonStyle===null)?'pa-3':'pa-'+buttonStyle.padding"
              :height="(buttonStyle===null)?42:buttonStyle.height"  @click="button(0)">
        <v-card-text class="text-muted fsize-2 pa-0 ">
          0
        </v-card-text>
      </v-card>
    </v-col>



    <v-col class="text-center " style="padding: 2px;" cols="4">
      <v-card :color="$vuetify.theme.dark? '#424242' : '#ececec'" elevation="0" :class="(buttonStyle===null)?'pa-3':'pa-'+buttonStyle.padding+' '"
              :height="(buttonStyle===null)?42:buttonStyle.height"  text @click="del">
        <v-card-text class="text-muted fsize-2 pa-0">
          <font-awesome-icon
              :icon="['fal', 'backspace']"
              style="color: #FF6969" class="font-size-xlg"
          />
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>

import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {library} from '@fortawesome/fontawesome-svg-core';
import {faCommentAltDollar, faHeart,faBackspace} from '@fortawesome/pro-light-svg-icons'


library.add(faCommentAltDollar, faHeart,faBackspace)
export default {
  name: "CashoutNumpad",
  components: {
    'font-awesome-icon': FontAwesomeIcon,
  },
  props: {
    doubleZero:{
      type:Boolean,
      require:false,
      default:true,
    },
    buttonStyle:{
      type:Object,
      require: false,
      default: null,
    }
  },
  methods: {
    button(number) {
      this.$emit('change', number)
    },
    del() {
      this.$emit('del')
    },

  }
}
</script>

<style scoped>

</style>