<template>
    <v-dialog v-model="showDialog" persistent scrollable width="600px">
        <v-card>
            <v-toolbar dense flat>
                <v-toolbar-title>{{ $t('settings.lang_changeIcon') }}</v-toolbar-title>

                <v-spacer/>

                <v-btn icon @click="closeDialog">
                    <v-icon>close</v-icon>
                </v-btn>
            </v-toolbar>

            <v-card-text style="min-height: 500px;">
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <fontawesome-picker :fieldLabel="$t('erp.lang_chooseWaregroupIcon')" :value="icon" v-model="icon"></fontawesome-picker>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>

            <v-card-actions>
                <v-btn text color="primary" @click="closeDialog">{{$t("generic.lang_cancel")}}</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" :disabled="icon === undefined || icon.length === 0" @click="saveIcon">{{ $t("generic.lang_save") }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import FontawesomePicker from "../../../../common/iconPicker";

    export default {
        name: "ChangeWaregroupIconDialog",

        components: {
            FontawesomePicker
        },

        props: {
            showDialog: Boolean,
            gridItem: Object
        },

        data() {
            return {
                icon: ""
            }
        },

        watch: {
            showDialog(val) {
                if(!val) {
                    this.icon = "";
                }
                else {
                    if(this.gridItem !== null) {
                        if(this.gridItem.icon !== null) {
                            this.icon = this.gridItem.icon;
                        }
                    }
                }
            }
        },

        methods: {
            closeDialog() {
                this.$emit("close");
            },
            saveIcon() {
                this.$emit("saveIcon", this.icon);
            },
        }
    }
</script>