<template>
  <div>
    <div v-if="loading" style="text-align: center; padding-top: 20px;">
      <v-progress-circular width="3" indeterminate color="primary"></v-progress-circular>
    </div>

    <v-btn v-if="!loading" style="margin-top: 20px;" color="primary" @click="showCreateDialog = true" small>
      {{ $t('settings.lang_createTemplate') }}
    </v-btn>

    <v-divider></v-divider>

    <v-simple-table v-if="!loading && templates.length > 0">
      <template v-slot:default>
        <thead>
        <tr>
          <th class="text-left">#</th>
          <th class="text-left">{{ $t('generic.lang_name') }}</th>
          <th class="text-left"></th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="template in templates" :key="template.id">
          <td>{{ template.id }}</td>
          <td>{{ template.name }}</td>
          <td>{{ templateType(template.type)}}</td>
          <td>
            <v-btn @click="selectTemplate(template)" color="primary" icon>
              <v-icon>edit</v-icon>
            </v-btn>

            <v-btn @click="deleteTemplate(template)" color="error" icon>
              <v-icon>delete</v-icon>
            </v-btn>
          </td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-card-text v-if="!loading && templates.length === 0">
      <v-alert text color="primary">
        <div style="text-align: center;">
          <p>{{ $t('settings.lang_thereAreNoTemplatesAvailable') }}</p>
          <v-divider></v-divider>
          <v-btn @click="showCreateDialog = true" text color="primary"> {{ $t('settings.lang_createTemplate') }} </v-btn>
        </div>
      </v-alert>
    </v-card-text>

    <POSGridTemplateCreateDialog :show-dialog="showCreateDialog"
                                 @close="showCreateDialog = false"></POSGridTemplateCreateDialog>
  </div>
</template>

<script>
import {ENDPOINTS} from "../../../../config";
import POSGridTemplateCreateDialog from "./POSGridTemplateCreateDialog";
import {Events} from "@/plugins/events";

export default {
  name: "POSGridTemplates",

  components: {
    POSGridTemplateCreateDialog
  },

  data() {
    return {
      loading: false,
      templates: [],
      showCreateDialog: false
    }
  },

  mounted() {
    this.getTemplates();
  },

  methods: {
    templateType(type) {
      if (type === 1) {
        return this.$t('generic.moduleGastronomyName');
      } else if (type === 2) {
        return this.$t('generic.moduleRetailName');
      } else if (type === 3) {
        return this.$t('delivery.lang_delivery');
      }
    },
    deleteTemplate(template) {
      let self = this;
      this.$swal({
        title: this.$t('erp.lang_deleteMealTypeType'),
        text: this.$t('erp.lang_deleteMealTypeType'),
        icon: "warning",
        cancelButtonText: this.$t('generic.lang_cancel'),
        confirmButtonText: this.$t('generic.lang_delete'),
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          this.axios.post(ENDPOINTS.SETTINGS.POSLAYOUT.TEMPLATES.DELETE, {
            templateID: template.id
          }).then((res) => {
            if (res.data.success === true) {
              Events.$emit("showSnackbar", {
                message: this.$t('erp.lang_itemSuccessfulDeleted'),
                color: "success"
              });

              this.getTemplates();
            } else if (res.data.success === false) {
              Events.$emit("showSnackbar", {
                message: res.data.msg,
                color: "error"
              });
            } else {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_errorOccurred'),
                color: "error"
              });
            }
          }).catch(() => {
            Events.$emit("showSnackbar", {
              message: this.$t('generic.lang_errorOccurred'),
              color: "error"
            });
          });
        },
        allowOutsideClick: () => !this.$swal.isLoading
      });
    },
    getTemplates() {
      this.loading = true;

      this.axios.get(ENDPOINTS.SETTINGS.POSLAYOUT.TEMPLATES.GET).then((res) => {
        if (res.data.success) {
          this.templates = res.data.templates.filter((template) => {
            if (template.type === 1 || template.type === 2 || template.type === 3) {
              return true;
            }
          });
        }

        this.loading = false;
      });
    },
    selectTemplate(template) {
      this.$router.push({
        name: 'settings.cashierSettings.layoutSettings.posGridTemplate', params: {
          id: template.id
        }
      })
    }
  }
}
</script>
