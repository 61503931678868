<template>
    <v-dialog :value="showDialog" persistent scrollable max-width="700px">
        <v-card>
            <v-toolbar dense flat>
                <v-toolbar-title>{{ $t('generic.lang_selectColor') }}</v-toolbar-title>

                <v-spacer/>

                <v-btn icon @click="closeDialog">
                    <v-icon>close</v-icon>
                </v-btn>
            </v-toolbar>

            <v-card-text style="max-height: 700px; padding: 0;">
                <v-color-picker
                    v-model="selectedColor"
                    mode="hexa"
                    show-swatches
                    hide-mode-switch
                    elevation="1"
                    width="600"
                    class="color-swatcher-without-transparency mx-auto my-8"
                ></v-color-picker>

                <!-- Remove color -->
                <v-btn color="error" text v-if="selectedColor !== null" @click="removeGridItemColor">{{ $t('generic.lang_removeColor') }}</v-btn>
            </v-card-text>

            <v-card-actions>
                <v-btn text color="primary" @click="closeDialog">{{$t("generic.lang_cancel")}}</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" :disabled="selectedColor === null" @click="saveColor">{{ $t('generic.lang_saveColor') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: "ColorPickerDialog",

        props: {
            showDialog: Boolean,
            gridItem: Object
        },

        data() {
            return {
                selectedColor: null
            }
        },

        watch: {
            showDialog(val) {
                if(!val) {
                    this.selectedColor = null;
                }
                else {
                    if(this.gridItem !== null) {
                        this.selectedColor = this.gridItem.color;
                    }
                }
            }
        },

        methods: {
            closeDialog() {
                this.$emit("close");
            },
            saveColor() {
                if (
                    typeof this.selectedColor === "object" &&
                    this.selectedColor !== null
                ) {
                    this.selectedColor = this.selectedColor.hex;
                }

                this.$emit("saveColor", this.selectedColor);
            },
            removeGridItemColor() {
                this.selectedColor = null;
                this.saveColor();
            }
        }
    }
</script>

<style>
.color-swatcher-without-transparency
  .v-color-picker__sliders
  .v-color-picker__alpha {
  visibility: hidden;
}

.color-swatcher-without-transparency
  .v-color-picker__sliders
  .v-color-picker__hue {
  margin-bottom: 0 !important;
}

.color-swatcher-without-transparency
  .v-color-picker__swatch:nth-child(20)
  > .v-color-picker__color:nth-child(3) {
  visibility: hidden;
}
</style>
