<template>
    <div>
        <page-title :heading="$t('settings.lang_nav_design') "  :subheading="$t('settings.lang_nav_design')" :icon=icon></page-title>

        <v-tabs>
            <v-tab>{{$t('erp.lang_items')}} / {{$t('erp.lang_nav_waregroups')}}</v-tab>

            <v-tab-item>
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <v-card>
                                <v-tabs>
                                    <v-tab>{{$t('settings.lang_templates')}}</v-tab>
                                    <v-tab>{{$t('settings.lang_assignTemplates')}}</v-tab>
                                    <v-tab>{{$t('settings.lang_settings')}}</v-tab>

                                    <!-- Templates -->
                                    <v-tab-item>
                                        <POSGridTemplates></POSGridTemplates>
                                    </v-tab-item>

                                    <!-- Assign -->
                                    <v-tab-item>
                                        <POSGridTemplateAssign></POSGridTemplateAssign>
                                    </v-tab-item>

                                  <!-- Settings  -->
                                  <v-tab-item>
                                    <DesignSettingComponent/>
                                  </v-tab-item>
                                </v-tabs>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </v-tab-item>
        </v-tabs>
    </div>
</template>

<script>
    import PageTitle from "../../../Layout/Components/PageTitle";
    import POSGridEditor from "../../../components/settings/cashier/layout/POSGridEditor";
    import POSGridTemplates from "../../../components/settings/cashier/layout/POSGridTemplates";
    import POSGridTemplateAssign from "../../../components/settings/cashier/layout/POSGridTemplateAssign";
    import DesignSettingComponent from "@/components/settings/cashier/layout/DesignSettingComponent";

    export default {
        name: "Layout",

        components: {
          DesignSettingComponent,
            POSGridTemplateAssign,
            POSGridTemplates,
            PageTitle,
            POSGridEditor
        },

        data: () => ({
            icon: 'pe-7s-plane icon-gradient bg-tempting-azure',
        })
    }
</script>