<template>
  <div>
    <p style="margin-left: 5px;">{{ $t('generic.lang_homePage') }} <span v-if="selectedGridItem1 !== null">/ {{
        selectedGridItem1.name
      }}</span><span v-if="selectedGridItem2 !== null"> / {{ selectedGridItem2.name }}</span></p>

    <v-btn :disabled="selectedGridItem1 === null" color="primary" small @click="goBack">
      <v-icon>arrow_left</v-icon>
    </v-btn>

    <v-btn :disabled="getSelectedItemType !== 'waregroup' || Object.keys(computedAssignments).length > 0"
           color="primary" @click="fillWaregroup" small>{{ $t('settings.lang_replenishWaregroup') }}
    </v-btn>

    <v-btn color="error" small @click="clearAll">
      <v-icon>delete</v-icon>
      {{ $t('generic.lang_clearAll') }}
    </v-btn>

    <v-btn color="success" small @click="saveTemplate" :disabled="saveLoading" :loading="saveLoading">
      <v-icon>save</v-icon>
      {{ $t('generic.lang_saveChanges') }}
    </v-btn>

    <div>
      <v-btn @click="gridPage--" :disabled="gridPage === 1" color="primary" small>
        <v-icon>arrow_drop_up</v-icon>
      </v-btn>

      <v-btn @click="gridPage++" color="primary" small>
        <v-icon>arrow_drop_down</v-icon>
      </v-btn>
      <v-switch class="ml-4" style="display: inline-block;" inset v-model="isMultiUser"
                :label="$t('settings.lang_multiUsers')">
        {{ $t('settings.lang_multiUsers') }}
      </v-switch>
      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field @focus="showTouchKeyboard"
                        :data-layout="KEYBOARD.KEYSETS.NUMERIC"
                        outlined type="number" v-model="nameLength" :label="$t('erp.lang_trimWareNameAfterXChars')"
                        dense :min="minLength"
                        :rules="[v=>( v!==null && v!=='' && Number(v)>=0) || $t('generic.lang_requiredField'),v=>(Number(v)===0 || Number(v)>=minLength)|| $t('settings.lang_minimumLengthIs')+' '+minLength]">

          </v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field @focus="showTouchKeyboard"
                        :data-layout="KEYBOARD.KEYSETS.NUMERIC"
                        outlined type="number" v-model="fontSize" :label="$t('settings.lang_fontsizeForItemname')" dense
                        :min="minFontSize"
                        :rules="[v=>(v!==null && v!=='' && Number(v)>=0) || $t('generic.lang_requiredField'),v=>(Number(v)>=minFontSize|| $t('settings.lang_minimumIs')+' '+minFontSize)]">

          </v-text-field>
        </v-col>
      </v-row>
    </div>

    <!-- Alert Message -->
    <v-alert :value="alertMessage.length > 0" style="margin-top: 10px;" dismissible text color="info"
             v-html="alertMessage"></v-alert>

    <v-divider></v-divider>

    <!-- Start Grid -->
    <div class="text-right mb-5">
      <v-btn
          small
          elevation="0"
          class="py-5 info-btn"
          :color="draggable ? 'green' : 'red'"
          @click="draggable = !draggable"
      >
        <font-awesome-icon v-if="draggable" :icon="['fal', 'lock-open']"/>
        <font-awesome-icon v-else :icon="['fal','lock']"/>
      </v-btn>
    </div>


    <div v-for="row in gridHeight" :key="row" style="display: flex;">
      <div v-for="col in gridWidth" :key="getGridPosition(row, col) + updatingDrop" class="grid-card"
           :style="{width: gridItemWidth, padding: '5px'}">

        <!-- Unassigned Position -->
        <v-card v-if="!isAssigned(getGridPosition(row, col))&& !(row*col === gridWidth*gridHeight&&currentLevel > 1)"
                elevation="1" :height="gridItemHeight"
                color="grey" v-ripple="{class: 'primary--text'}">
          <v-card-text>
            <div style="text-align: center;">
              <div v-if="allowWaregroupToAssign"
                   @click="assignWaregroup(getGridPosition(row, col))">
                <v-icon style="margin-top: -20px;" size="30">add</v-icon>
                <p style="margin-top: -5px;">{{ $t('generic.lang_waregroup') }}</p>
              </div>

              <v-divider v-if="allowWaregroupToAssign"></v-divider>

              <div @click="assignItem(getGridPosition(row, col))">
                <v-icon style="margin-top: -10px;" size="30">add</v-icon>
                <p style="margin-top: -5px;">{{ $t('erp.lang_item') }}</p>
              </div>
            </div>
          </v-card-text>
        </v-card>
        <!-- Assigned position -->
        <v-menu v-if="isAssigned(getGridPosition(row, col))&& !(row*col === gridWidth*gridHeight&&currentLevel > 1)"
                offset-overflow offset-x>
          <template v-slot:activator="{ on }">
            <v-card elevation="1" :height="gridItemHeight"
                    :color="computedAssignments[getGridPosition(row, col)].color === null ? 'grey' : computedAssignments[getGridPosition(row, col)].color"
                    v-on="on"
                    v-ripple="{class: 'primary--text'}"
                    :draggable="draggable"
                    @dragstart="onDragStart($event, computedAssignments[getGridPosition(row, col)], getGridPosition(row, col))"
                    @dragover="onDragOver($event, computedAssignments[getGridPosition(row, col)], getGridPosition(row, col))"
                    @drop="onDrop(getGridPosition(row, col))"
            >
              <v-card-text>
                <div
                    :style="{textAlign: 'center', color: (checkColorLightOrDark((computedAssignments[getGridPosition(row, col)].color === null ? 'grey' : computedAssignments[getGridPosition(row, col)].color)) === 'light' ? 'black' : 'white')}">
                  {{
                    (computedAssignments[getGridPosition(row, col)].name.length === 0 ? computedAssignments[getGridPosition(row, col)].id : computedAssignments[getGridPosition(row, col)].name)
                  }}
                </div>

                <div style="text-align: center;">
                  <font-awesome-icon v-if="computedAssignments[getGridPosition(row, col)].icon !== null"
                                     :style="{marginTop: '10px', fontSize: '30px', color: (checkColorLightOrDark((computedAssignments[getGridPosition(row, col)].color === null ? 'grey' : computedAssignments[getGridPosition(row, col)].color)) === 'light' ? 'black' : 'white')}"
                                     :icon="['fal', computedAssignments[getGridPosition(row, col)].icon]"/>
                </div>
              </v-card-text>
            </v-card>
          </template>

          <!-- Waregroup -->
          <v-list>
            <v-list-item
                v-if="computedAssignments[getGridPosition(row, col)].type === 'waregroup' || computedAssignments[getGridPosition(row, col)].type === 'waregroup_virtual'"
                @click="selectGridItem(computedAssignments[getGridPosition(row, col)])">
              <v-list-item-icon>
                <v-icon>input</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title> {{ $t('settings.lang_lookAt') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
                v-if="computedAssignments[getGridPosition(row, col)].type === 'waregroup' || computedAssignments[getGridPosition(row, col)].type === 'waregroup_virtual'"
                @click="changeWaregroupIcon(computedAssignments[getGridPosition(row, col)])">
              <v-list-item-icon>
                <v-icon>insert_emoticon</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title> {{ $t('settings.lang_changeIcon') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="changeGridItemColor(computedAssignments[getGridPosition(row, col)])">
              <v-list-item-icon>
                <v-icon>color_lens</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title> {{ $t('settings.lang_changeColor') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="computedAssignments[getGridPosition(row, col)].type === 'waregroup_virtual'"
                         @click="changeWaregroupName(computedAssignments[getGridPosition(row, col)])">
              <v-list-item-icon>
                <v-icon>edit</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title> {{ $t('settings.lang_changeName') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <!-- SHOW V-LIST-ITEM WITH WARNING DIALOG -->
            <GridItemDeleteDialog @delete="deleteGridItem(getGridPosition(row, col))"></GridItemDeleteDialog>
          </v-list>
        </v-menu>

        <div v-if="currentLevel > 1 && (row*col === gridWidth*gridHeight)"
             :style="{height: gridItemHeight,paddingTop:'5px'}">
          <v-card height="100%" width="100%" elevation="1" @click="goBack" dark>
            <v-card-text class="fill-height pa-2">
              <p class="text-center h-100 d-flex align-center justify-center ma-0">{{ $t('generic.lang_prev') }}</p>
            </v-card-text>
          </v-card>
        </div>
      </div>
    </div>

    <WaregroupsDialog :show-dialog="showWaregroupsDialog" @close="showWaregroupsDialog = false"
                      @selectWaregroup="selectedAssignWaregroup"></WaregroupsDialog>
    <ItemsDialog :show-dialog="showItemsDialog" @close="showItemsDialog = false"
                 @selectItem="selectedAssignItem"></ItemsDialog>
    <ColorPickerDialog :show-dialog="showColorPickerDialog" :grid-item="dialogGridItem"
                       @close="showColorPickerDialog = false" @saveColor="saveGridItemColor"></ColorPickerDialog>
    <ChangeWaregroupNameDialog :show-dialog="showWaregroupChangeNameDialog" :grid-item="dialogGridItem"
                               @close="showWaregroupChangeNameDialog = false"
                               @saveName="saveWaregroupName"></ChangeWaregroupNameDialog>
    <ChangeWaregroupIconDialog :show-dialog="showWaregroupChangeIconDialog" :grid-item="dialogGridItem"
                               @close="showWaregroupChangeIconDialog = false"
                               @saveIcon="saveWaregroupIcon"></ChangeWaregroupIconDialog>

    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard id="onScreenKeyboard" class="internalWidth" :options="touchKeyboard.options"
                          :defaultKeySet="touchKeyboard.keySet" v-if="touchKeyboard.visible"
                          :layout="touchKeyboard.layout" :cancel="hideTouchKeyboard"
                          :accept="hideTouchKeyboard" :input="touchKeyboard.input"/>
    </div>
  </div>
</template>

<script>
import WaregroupsDialog from "./WaregroupsDialog";
import ItemsDialog from "./ItemsDialog";
import ColorPickerDialog from "./ColorPickerDialog";
import ChangeWaregroupNameDialog from "./ChangeWaregroupNameDialog";
import ChangeWaregroupIconDialog from "./ChangeWaregroupIconDialog";

import {mapState} from "vuex";

// IMPORT FONT AWESOME ICONS
//
// MUST MATCH WITH components/erp/EditItemGroup.vue
//
import {library} from '@fortawesome/fontawesome-svg-core';
import mixin from '@/mixins/KeyboardMixIns';

import {
  faLock,
  faLockOpen,
  faProjector,
  faTablet,
  faIceCream,
  faGiftCard,
  faHotdog,
  faCocktail,
  faMeat,
  faGifts,
  faHamburger,
  faCheeseburger,
  faFrenchFries,
  faPizzaSlice,
  faShishKebab,
  faFish,
  faCroissant,
  faSalad,
  faTaco,
  faSandwich,
  faSoup,
  faPersonCarry,
  faStopwatch,
  faBiking,
  faUserHeadset,
  faMapMarkerAlt,
  faHatChef,
  faKnifeKitchen,
  faWindowClose,
  faCheck,
  faFlagCheckered,
  faBinoculars,
  faPizza,

  faBeer,

  faMugHot,

  faGlassCheers,

  faGlass,

  faUtensils,

  faFishCooked,

  faTurkey,

  faFlowerDaffodil,

  faFlowerTulip,

  faDumbbell,

  faMouse,

  faPlug,

  faWebcam,

  faUsbDrive,

  faWatchCalculator,

  faSdCard,

  faMobile,

  faGameConsoleHandheld,

  faGamepadAlt,

  faFlashlight,

  faBaseball,

  faToiletPaper,

  faShovel,


  faBackpack,

  faAxe,

  faHeadphones,

  faMp3Player,

  faDroneAlt,

  faCameraPolaroid,

  faAlbum,
  faKeyboard,
  faCompactDisc,

} from '@fortawesome/pro-light-svg-icons';

library.add(
    faLock,
    faLockOpen,
    faCompactDisc,
    faHotdog,
    faCocktail,
    faMeat,
    faGifts,
    faHamburger,
    faCheeseburger,
    faFrenchFries,
    faPizzaSlice,
    faShishKebab,
    faFish,
    faSalad,
    faTaco,
    faSandwich,
    faSoup,
    faPersonCarry,
    faStopwatch,
    faBiking,
    faUserHeadset,
    faMapMarkerAlt,
    faHatChef,
    faKnifeKitchen,
    faWindowClose,
    faCheck,
    faFlagCheckered,
    faProjector,
    faBinoculars,
    faPizza,
    faBeer,
    faMugHot,
    faGlassCheers,
    faGlass,
    faUtensils,
    faCroissant,
    faFishCooked,
    faTurkey,
    faIceCream,
    faFlowerDaffodil,
    faFlowerTulip,
    faDumbbell,
    faMouse,
    faPlug,
    faWebcam,
    faTablet,
    faUsbDrive,
    faWatchCalculator,
    faSdCard,
    faMobile,
    faIceCream,
    faGameConsoleHandheld,
    faGamepadAlt,
    faFlashlight,
    faBaseball,
    faShishKebab,
    faToiletPaper,
    faShovel,
    faFlashlight,
    faBinoculars,
    faBackpack,
    faAxe,
    faHeadphones,
    faMp3Player,
    faDroneAlt,
    faCameraPolaroid,
    faAlbum,
    faKeyboard,
    faTablet,
    faIceCream,
    faGiftCard,
);
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {ENDPOINTS} from "@/config";
import {checkColorLightOrDark} from "@/plugins/checkColorLightOrDark";
import GridItemDeleteDialog from "@/components/settings/cashier/layout/posgrideditor/GridItemDeleteDialog";
import axios from "axios";
import {Events} from "../../../../../plugins/events";

export default {
  name: "Grid",
  mixins: [mixin],
  props: {
    template: Object,
    gridItemWidth: String,
    gridItemHeight: String,
    gridHeight: Number,
    gridWidth: Number,
    multiUser: Boolean,
  },

  components: {
    GridItemDeleteDialog,
    ChangeWaregroupIconDialog,
    ChangeWaregroupNameDialog,
    ColorPickerDialog,
    ItemsDialog,
    WaregroupsDialog,
    'font-awesome-icon': FontAwesomeIcon
  },


  data() {
    return {
      draggable: false,
      modelInDrag: null,
      modelInDragIndex: null,
      assignments: {},
      showWaregroupsDialog: false,
      showItemsDialog: false,
      showColorPickerDialog: false,
      showWaregroupChangeNameDialog: false,
      showWaregroupChangeIconDialog: false,
      dialogGridItem: null,
      gridPage: 1,
      selectedPosition: null,
      selectedGridItem1: null,
      selectedGridItem2: null,
      saveLoading: false,
      isMultiUser: 0,
      alertMessage: "",
      minLength: 10,
      minFontSize: 12,
      nameLength: this.minLength,
      fontSize: this.minFontSize,
      updatingDrop: false,
    }
  },
  watch: {
    gridPage(v) {
      // console.log(v);
    }
  },
  mounted() {
    this.isMultiUser = this.multiUser;
    if (this.template.templateJSON.length > 0 || Object.keys(this.template.templateJSON).length > 0) {
      this.assignments = this.template.templateJSON;
    } else {
      this.assignments = {};
    }
  },

  computed: {
    ...mapState([
      'app'
    ]),
    computedAssignments() {
      if (this.currentLevel === 1) {
        return this.assignments;
      }

      if (this.currentLevel === 2) {
        return this.selectedGridItem1.assignments;
      }

      if (this.currentLevel === 3) {
        return this.selectedGridItem2.assignments;
      }

      return {};
    },
    currentLevel() {
      if (this.selectedGridItem1 === null) {
        return 1;
      }

      if (this.selectedGridItem2 === null) {
        return 2;
      }

      return 3;

    },
    allowWaregroupToAssign() {
      return this.currentLevel < 3;
    },
    getSelectedItemType() {
      if (this.selectedGridItem1 !== null) {
        if (this.selectedGridItem2 !== null) {
          return this.selectedGridItem2.type;
        }

        return this.selectedGridItem1.type;
      }

      return null;
    },
    selectedGridItem() {
      if (this.selectedGridItem1 !== null) {
        if (this.selectedGridItem2 !== null) {
          return this.selectedGridItem2;
        }

        return this.selectedGridItem1;
      }

      return null;
    }
  },

  methods: {
    checkColorLightOrDark,
    getGridPosition(row, col) {
      return (col + (this.gridWidth * (row - 1))) + ((this.gridPage - 1) * (this.gridWidth * this.gridHeight));
    },
    selectGridItem(item) {
      this.gridPage = 1;

      if (item.type !== "item") {
        if (item.level === 1) {
          this.selectedGridItem1 = item;
        }

        if (item.level === 2) {
          this.selectedGridItem2 = item;
        }

      }
    },
    deleteGridItem(position) {
      this.$delete(this.computedAssignments, position);
    },
    changeGridItemColor(item) {
      this.dialogGridItem = item;
      this.showColorPickerDialog = true;
    },
    saveGridItemColor(color) {
      this.dialogGridItem.color = color;
      this.dialogGridItem = null;

      this.showColorPickerDialog = false;
    },
    changeWaregroupName(waregroup) {
      this.dialogGridItem = waregroup;

      this.showWaregroupChangeNameDialog = true;
    },
    saveWaregroupName(name) {
      this.dialogGridItem.name = name;
      this.dialogGridItem = null;

      this.showWaregroupChangeNameDialog = false;
    },
    changeWaregroupIcon(waregroup) {
      this.dialogGridItem = waregroup;

      this.showWaregroupChangeIconDialog = true;
    },
    saveWaregroupIcon(icon) {
      this.dialogGridItem.icon = icon;
      this.dialogGridItem = null;

      this.showWaregroupChangeIconDialog = false;
    },
    async fillWaregroup() {
      let newItems = [];
      if (this.app.useAPIForPOSActions) {
        await this.axios.post('get/indexeddb/wares/', {
          wgID: this.selectedGridItem.id,
        }).then((res) => {
          newItems = res.data;
        }).catch((err) => {
          Events.$emit("showSnackbar", {
            message: this.$t('erp.lang_sizeVariation_added'),
            color: "success"
          });
        });
      } else {
        newItems = await this.$store.dispatch("items/getItemsByItemGroupIDSortByName", this.selectedGridItem.id);
      }

      //ADD ITEMS
      let position = 1;
      newItems.forEach((item) => {
        //when autofilling waregroup with its items prevent putting an item's variations
        if (item.isVariation)
          return;

        this.selectedAssignItem(item, position);

        position++;
      })

    },
    goBack() {
      if (this.currentLevel === 2) {
        this.selectedGridItem1 = null;
      }

      if (this.currentLevel === 3) {
        this.selectedGridItem2 = null;
      }
    },
    isAssigned(position) {
      if (!this.computedAssignments.hasOwnProperty(position))
        return false;

      if (this.computedAssignments[position] === null)
        return false;

      return true;
    },
    assignWaregroup(position) {
      this.selectedPosition = position;
      this.showWaregroupsDialog = true;
    },
    assignItem(position) {
      this.selectedPosition = position;
      this.showItemsDialog = true;
    },
    selectedAssignWaregroup(waregroup, virtual = false) {
      this.$set(this.computedAssignments, this.selectedPosition, {
        type: virtual ? "waregroup_virtual" : "waregroup",
        id: waregroup.id,
        name: waregroup.name,
        level: this.currentLevel,
        position: this.selectedPosition,
        color: (virtual === false ? (waregroup.backgroundColor.length > 0 ? waregroup.backgroundColor : null) : null),
        icon: (virtual === false ? (waregroup.faIcon.length > 0 ? waregroup.faIcon : null) : null),
        assignments: {}
      });

      this.showWaregroupsDialog = false;
      this.selectedPosition = null;
    },
    selectedAssignItem(item, position) {
      let assigningPosition = this.selectedPosition;

      if (position != null)
        assigningPosition = position;

      this.$set(this.computedAssignments, assigningPosition, {
        type: "item",
        id: item.id,
        name: item.name,
        level: this.currentLevel,
        position: assigningPosition,
        color: (item.backgroundColor.length > 0 ? item.backgroundColor : null),
        icon: null
      });

      this.showItemsDialog = false;
      this.selectedPosition = null;
    },
    clearAll() {
      //CLEAR ASSIGNMENTS BY EACH LEVEL
      if (this.currentLevel === 1)
        this.assignments = {};

      if (this.currentLevel === 2)
        this.$set(this.assignments[this.selectedGridItem1.position], 'assignments', {});

      if (this.currentLevel === 3)
        this.$set(this.assignments[this.selectedGridItem1.position].assignments[this.selectedGridItem2.position], 'assignments', {});
    },
    saveTemplate() {
      this.saveLoading = true;
      this.alertMessage = "";

      this.axios.post(ENDPOINTS.SETTINGS.POSLAYOUT.TEMPLATE.UPDATE, {
        templateID: this.template.id,
        templateJSON: this.assignments,
        gridWidth: this.template.gridWidth,
        gridHeight: this.template.gridHeight,
        multiUser: (this.isMultiUser) ? 1 : 0,
      }).then((res) => {
        if (res.data.success) {
          this.alertMessage = this.$t('generic.lang_changesHaveBeenSaved');
        } else {
          this.alertMessage = res.data.msg;
        }

        this.saveLoading = false;
      }).catch(() => {
        this.alertMessage = this.$t('generic.lang_anErrorOccurredPLSTryAgain');

        this.saveLoading = false;
      })
    },
    onDragStart(evt, item, index) {
      this.modelInDrag = item;
      this.modelInDragIndex = index;

      evt.dataTransfer.dropEffect = 'move';
      evt.dataTransfer.effectAllowed = 'move';
    },
    onDragOver(evt) {
      evt.preventDefault();
      evt.dataTransfer.dropEffect = "move";
    },
    indexesExists(elements, index) {
      // checks index if exists for both types object or array
      return Array.isArray(elements)
          ? !!elements[index]
          : elements.hasOwnProperty(index)
    },
    onDrop(dropIndex) {
      this.updatingDrop = true;
      const assignmentsRef = this.selectedGridItem ? this.selectedGridItem.assignments : this.assignments;
      let skippedIndexes = 1;
      if (dropIndex < this.modelInDragIndex) {
        for (let i = this.modelInDragIndex, lastFilledIndex = this.modelInDragIndex; i > dropIndex; i--) {
          if (assignmentsRef[lastFilledIndex - skippedIndexes]) {
            lastFilledIndex = skippedIndexes > 1 ? lastFilledIndex : i;
            assignmentsRef[lastFilledIndex - skippedIndexes].position = lastFilledIndex;
            assignmentsRef[lastFilledIndex] = assignmentsRef[lastFilledIndex - skippedIndexes];
            skippedIndexes = 1;
            lastFilledIndex = i-1;
          } else {
            skippedIndexes += 1;
          }

        }
      } else if (dropIndex > this.modelInDragIndex) {
        for (let i = this.modelInDragIndex, lastFilledIndex = this.modelInDragIndex; i < dropIndex; i++) {
          if (assignmentsRef[lastFilledIndex + skippedIndexes]) {
            lastFilledIndex = skippedIndexes > 1 ? lastFilledIndex : i;
            assignmentsRef[lastFilledIndex + skippedIndexes].position = lastFilledIndex;
            assignmentsRef[lastFilledIndex] = assignmentsRef[lastFilledIndex + skippedIndexes];
            skippedIndexes = 1;
            lastFilledIndex = i+1;
          } else {
            skippedIndexes += 1;
          }
        }
      }
      assignmentsRef[dropIndex] = this.modelInDrag;
      assignmentsRef[dropIndex].position = dropIndex;

      this.updatingDrop = false;
    },
  },
}
</script>

<style scoped>
.info-btn {
  font-size: 18px;
  background-color: lightgrey;
  border: solid 1px rgba(0, 0, 0, 0.2);
}

.grid-card {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
</style>