<template>
  <div>
    <v-layout>
      <v-flex width="100px">
        <v-menu
          v-model="dateMenu"
          ref="dateValue"
          :close-on-content-click="false"
          :return-value.sync="dateValue"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="200px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              outlined
              :clearable="clearable"
              @click:clear="dateValue = null"
              v-model="value"
              prepend-inner-icon="event"
              :label="label"
              readonly
              :rules="rules"
              v-on="on"
              :dense="dense"
              :hide-details="hideDetails"
            ></v-text-field>
          </template>

          <v-date-picker
            locale="de"
            v-if="dateMenu"
            v-model="dateValue"
            :type="type ? type : 'date'"
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="dateMenu = false">
              {{ $t("generic.lang_cancel") }}
            </v-btn>
            <v-btn text color="primary" @click="$refs.dateValue.save(dateValue)">
              {{ $t("generic.lang_apply") }}
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {
  props: [
    "label",
    "value",
    "dense",
    "hideDetails",
    "rules",
    "clearable",
    "type",
  ],
  data() {
    return {
      dateValue: this.value && this.value.length > 0 ? this.value : null,
      dateMenu: false,
    };
  },
  methods: {
    emitVal: function () {
      let self = this;
      let currentDate = new Date();
      if (this.dateValue == null || this.dateValue.length <= 0) {
        this.$emit("input", null);
      } else {
        this.$emit("input", this.dateValue);
      }
    },
    toggleToDate: function () {
      this.dateMenu = true;
    },
  },
  watch: {
    dateValue: function (val) {
      this.emitVal();
    },
  },
};
</script>
