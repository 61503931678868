<template>
  <v-container>
    <v-form ref="form" v-model="valid">
      <v-row>
        <v-col cols="6">
          <!-- start: desktop config -->
          <v-card outlined>
            <v-card-title :class="[this.$vuetify.theme.dark? 'dark-bg' : '', 'card-header']">
              {{ $t('generic.lang_desktop') }}
            </v-card-title>
            <v-card-text class="pt-5">
              <v-row>
                <v-col cols="12">
                  <v-text-field
                      :data-layout="KEYBOARD.KEYSETS.NUMERIC" :disabled="loading"
                      :rules="[v=>( v!==null && v!=='' && Number(v)>=0) || $t('generic.lang_requiredField'),v=>(Number(v)===0 || Number(v)>=minLength)|| $t('settings.lang_minimumLengthIs') + ' ' + minLength]"
                      @focus="showTouchKeyboard"
                      autocomplete="off"
                      dense
                      :loading="loading"
                      outlined
                      :label="$t('erp.lang_trimWareNameAfterXChars')"
                      v-model="nameLength"
                  />
                </v-col>

                <v-col class="pt-0" cols="12">
                  <v-text-field
                      :data-layout="KEYBOARD.KEYSETS.NUMERIC" :disabled="loading"
                      :rules="[v=>(v!==null && v!=='' && Number(v)>=0) || $t('generic.lang_requiredField'),v=>(Number(v)>=minFontSize|| $t('settings.lang_minimumIs') + ' ' + minFontSize)]"
                      @focus="showTouchKeyboard"
                      min="0"
                      :loading="loading"
                      autocomplete="off"
                      dense
                      :label="$t('settings.lang_fontsizeForItemname')"
                      outlined
                      type="number"
                      v-model="fontSize"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <!-- end: desktop config -->
        </v-col>


        <v-col cols="6">
          <!-- start: mobile config -->
          <v-card outlined>
            <v-card-title :class="[this.$vuetify.theme.dark? 'dark-bg' : '', 'card-header']">
              {{ $t('customers.lang_cust_handy') }}
            </v-card-title>
            <v-card-text class="pt-5">
              <v-row>
                <v-col cols="12">
                  <v-text-field
                      :data-layout="KEYBOARD.KEYSETS.NUMERIC" :disabled="loading"
                      :rules="[v=>( v!==null && v!=='' && Number(v)>=0) || $t('generic.lang_requiredField'),v=>(Number(v)===0 || Number(v)>=mobileMinLength)|| $t('settings.lang_minimumLengthIs') + ' ' + mobileMinLength]"
                      @focus="showTouchKeyboard"
                      autocomplete="off"
                      dense
                      :loading="loading"
                      outlined
                      :label="$t('erp.lang_trimWareNameAfterXChars')"
                      v-model="mobileNameLength"
                  />
                </v-col>

                <v-col class="pt-0" cols="12">
                  <v-text-field
                      :data-layout="KEYBOARD.KEYSETS.NUMERIC" :disabled="loading"
                      :rules="[v=>(v!==null && v!=='' && Number(v)>=0) || $t('generic.lang_requiredField'),v=>(Number(v)>=mobileMinFontSize|| $t('settings.lang_minimumIs') + ' ' + mobileMinFontSize)]"
                      @focus="showTouchKeyboard"
                      min="0"
                      :loading="loading"
                      autocomplete="off"
                      dense
                      :label="$t('settings.lang_fontsizeForItemname')"
                      outlined
                      type="number"
                      v-model="mobileFontSize"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <!-- end: mobile config -->
        </v-col>

      </v-row>

      <v-row>
        <v-col cols="6">
          <v-checkbox :true-value="1" :false-value="0" v-model="showItemPLU"
                      :label="$t('settings.lang_settingDisplayNoOnPLU')"></v-checkbox>
        </v-col>
        <v-col cols="6">
          <v-switch :true-value="1" :false-value="0" v-model="layoutSettings_useServerSideRendering"
                      :label="$t('settings.lang_enableServersideGeneration')"></v-switch>
        </v-col>
        <v-col cols="12" md="6">
          <v-select v-model="layoutSettings_itemgroupsSorting"
                    :items="itemgroupsSortItems"
                    :label="$i18n.t('erp.lang_sortItemsBy')"
                    item-disabled="disable"
                    item-text="name"
                    item-value="value"
                    outlined
                    dense
          ></v-select>
        </v-col>
      </v-row>
    </v-form>
    <v-row>
      <v-divider/>
      <v-col cols="12" align="right">
        <v-btn :disabled="loading||!valid" @click="save()" :loading="loading" color="success" small>
          {{ $t('generic.lang_apply') }}
        </v-btn>
      </v-col>
    </v-row>
    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard id="onScreenKeyboard" class="internalWidth" :options="touchKeyboard.options"
                          :defaultKeySet="touchKeyboard.keySet" v-if="touchKeyboard.visible"
                          :layout="touchKeyboard.layout" :cancel="hideTouchKeyboard"
                          :accept="hideTouchKeyboard" :input="touchKeyboard.input"/>
    </div>
  </v-container>
</template>

<script>

import {Events} from "@/plugins/events";
import mixin from "@/mixins/KeyboardMixIns";
import {ENDPOINTS} from "@/config";

export default {
  name: "DesignSettingComponent",

  mixins: [mixin],

  data() {
    return {
      loading: false,
      valid: false,
      minLength: 10,
      minFontSize: 12,
      mobileMinLength: 10,
      mobileMinFontSize: 12,
      nameLength: null,
      fontSize: null,

      mobileNameLength: null,
      mobileFontSize: null,
      showItemPLU: 0,
      layoutSettings_useServerSideRendering:0,
      layoutSettings_itemgroupsSorting: "standard",
    }
  },
  watch: {
    layoutSettings_useServerSideRendering(value) {
      if(value) return;
      if (this.layoutSettings_itemgroupsSorting == 'asc_sales' || this.layoutSettings_itemgroupsSorting == 'desc_sales') {
        this.layoutSettings_itemgroupsSorting = "standard";
      }
    }
  },
  computed: {
    itemgroupsSortItems() {
      return [
        {
          name: this.$t('generic.lang_standard'),
          value: "standard",
          disable: false
        },
        {
          name: this.$t('generic.lang_alphabetAsc'),
          value: "asc_alpha",
          disable: false
        },
        {
          name: this.$t('generic.lang_alphabetDesc'),
          value: "desc_alpha",
          disable: false
        },
        {
          name: this.$t('generic.lang_bySalesAscending'),
          value: "asc_sales",
          disable: this.layoutSettings_useServerSideRendering ? false : true,
        },
        {
          name: this.$t('generic.lang_bySalesDescending'),
          value: "desc_sales",
          disable: this.layoutSettings_useServerSideRendering ? false : true,
        },
        {
          name: this.$t('generic.lang_byPriceAscending'),
          value: "asc_price",
          disable: false
        },
        {
          name: this.$t('generic.lang_byPriceDescending'),
          value: "desc_price",
          disable: false
        },
      ];
    }
  },

  methods: {
    getData() {
      this.loading = true;
      this.axios.post(ENDPOINTS.SETTINGS.POSLAYOUT.CONFIG.GET)
          .then(res => {
            if (res.data.success) {
              this.fontSize = res.data.layoutSettings_fontSize;
              this.nameLength = res.data.layoutSettings_itemNameLength;

              this.mobileFontSize = res.data.layoutSettings_mobileFontSize;
              this.mobileNameLength = res.data.layoutSettings_mobileItemNameLength;
              this.showItemPLU = res.data.layoutSettings_showItemPLU;
              this.layoutSettings_useServerSideRendering = res.data.layoutSettings_useServerSideRendering;
              this.layoutSettings_itemgroupsSorting = res.data.layoutSettings_itemgroupsSorting;
            } else {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_errorOccurred'),
                color: "error"
              });
            }
          }).catch(err => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        })
      }).finally(() => {
        this.loading = false;
      })
    },
    save() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.loading = true;
      this.axios.post(ENDPOINTS.SETTINGS.POSLAYOUT.CONFIG.UPDATE, {
        layoutSettings_itemNameLength: this.nameLength,
        layoutSettings_fontSize: this.fontSize,
        layoutSettings_mobileItemNameLength: this.mobileNameLength,
        layoutSettings_mobileFontSize: this.mobileFontSize,
        layoutSettings_showItemPLU: this.showItemPLU,
        layoutSettings_useServerSideRendering:this.layoutSettings_useServerSideRendering,
        layoutSettings_itemgroupsSorting:this.layoutSettings_itemgroupsSorting
      }).then(res => {
        if (res.data.success) {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_actionSuccessful'),
            color: "success",
          });
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch(err => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      }).finally(() => {
        this.loading = false;
      })
    },
  },

  mounted() {
    this.getData()
  }
}
</script>
