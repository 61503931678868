<template>
  <v-dialog
    v-model="showDialog"
    max-width="800px"
    scrollable
    @click:outside="closeDialog"
    @keydown="$event.keyCode === 27 ? closeDialog() : ''"
  >
    <v-card width="100%">
      <v-toolbar
        class="text-black heading pt-1 pl-4 pr-1 pb-1"
        elevation="0"
        dense
      >
        <v-toolbar-title>
          {{ $t('customers.lang_cust_select_shipping_contact') }}
        </v-toolbar-title>
        <v-spacer />
        <v-icon @click="closeDialog">mdi-close</v-icon>
      </v-toolbar>

      <v-divider class="ma-0" />

      <v-card-text class="mt-4 pt-1">
        <v-autocomplete
          v-model="model"
          :items="shippingContacts"
          :disabled="loading"
          :label="$t('customers.lang_cust_select_shipping_contact')"
          item-text="id"
          item-value="id"
          outlined
          dense
        >
          <template v-slot:selection="data">
            {{ data.item.full_name }}
          </template>

          <template v-slot:item="data">
            <template>
              <v-list-item-content @click="setContact(data.item)">
                <v-list-item-title v-text="data.item.full_name"/>
                <v-list-item-subtitle v-text="data.item.streetNumber + ' ' + data.item.street"/>
                <v-list-item-subtitle v-text="data.item.city ? data.item.city + ', ' + data.item.zip : data.item.zip"/>
              </v-list-item-content>
            </template>
          </template>
        </v-autocomplete>
      </v-card-text>

      <v-card-actions>
        <v-btn @click="closeDialog" color="error" text class="">{{
          $t("generic.lang_cancel")
        }}</v-btn>

        <v-btn
          :disabled="!selectedContact"
          :loading="loading"
          color="success"
          @click="selectContact"
          >{{ $t("generic.lang_selectContact") }}
        </v-btn>
      </v-card-actions>

      <div
        id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard"
        style="z-index: 299 !important"
      >
        <vue-touch-keyboard
          v-if="touchKeyboard.visible"
          id="onScreenKeyboard"
          :accept="hideTouchKeyboard"
          :cancel="hideTouchKeyboard"
          :defaultKeySet="touchKeyboard.keySet"
          :input="touchKeyboard.input"
          :layout="touchKeyboard.layout"
          :options="touchKeyboard.options"
          class="internalWidthExpanded"
        />
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { ENDPOINTS } from "@/config";
import KeyboardMixIns from "../../mixins/KeyboardMixIns";

export default {
  name: "ShippingContactSearchComponent",
  mixins: [KeyboardMixIns],
  props: {
    showDialog: Boolean,
    parentCustomerId: {
      required: false,
    },
    shippingContact:{
      required:false
    }
  },
  data() {
    return {
      focus: false,
      loading: false,
      model: null,
      shippingContacts: [],
      selectedContact: null,
      company: "",
      firstName: "",
      lastName: "",
      street: "",
      streetNumber: "",
      zip: "",
      city: "",
      email: "",
      number: "",
      rules: {
        firstNameRules: (v) => !!v || this.$t("generic.lang_requiredField"),
        lastNameRules: (v) => !!v || this.$t("generic.lang_requiredField"),
      },
    };
  },
  watch: {
    showDialog() {
      // Items have already been loaded
      if (Array.isArray(this.shippingContacts) && this.shippingContacts.length)
        return;

      // Items have already been requested
      if (this.loading) return;

      // Lazily load input items
      this.loadShippingContacts();
    },
    shippingContact(val){
      if(val){
        this.selectedContact = val;
        this.model = this.selectedContact.id;
      }
    },
  },

  methods: {
    loadShippingContacts() {
      this.loading = true;

      this.axios
        .post(ENDPOINTS.CUSTOMERS.CUSTOMER.SHIPPINGCONTACTS, {
          parentCustomerId: this.$props.parentCustomerId,
        })
        .then((res) => res)
        .then((res) => {
          if (res.status === 200) {
            if (res.data.results.length > 0) {
              this.shippingContacts = res.data.results;
            } else {
              this.shippingContacts = [];
            }
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    setContact(contact) {
      this.selectedContact = contact;
    },
    selectContact() {
      if (this.selectedContact) {
        let shippingContact = {
          id: this.selectedContact.id,
          company: this.selectedContact.company,
          first_name: this.selectedContact.first_name,
          last_name: this.selectedContact.last_name,
          street: this.selectedContact.street,
          streetNumber: this.selectedContact.streetNumber,
          zip: this.selectedContact.zip,
          city: this.selectedContact.city,
          email: this.selectedContact.email,
          mobile: this.selectedContact.mobile,
        };
        this.$emit("selectShippingContact", shippingContact);
        this.$emit("close");
      }
    },
    closeDialog() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
</style>