<template>
<v-dialog v-model="showDialog" max-width="800px" scrollable @click:outside="closeDialog" @keydown="($event.keyCode===27)?closeDialog():''">
  <v-card width="100%">
    <v-toolbar class="text-black heading pt-1 pl-4 pr-1 pb-1" elevation="0" dense>
      <v-toolbar-title>
        {{$t('generic.lang_selectCustomer')}}
      </v-toolbar-title>
      <v-spacer/>
     <v-icon @click="closeDialog">mdi-close</v-icon>
    </v-toolbar>
    <v-divider class="ma-0"/>

  <v-card-text>
    <div class="mt-4 pt-1">
      <v-menu :close-on-content-click="false"
              :nudge-right="0"
              transition="scroll-y-transition" v-model="focus"
              offset-y style="width:100%;"
              bottom>
        <template v-slot:activator="{ on }" >
          <v-text-field clearable v-on="on" hide-details outlined dense flat class="mx-1" :label="$t('customers.lang_cust_search')"
                        type="text" v-model="searchInput" :loading="loadingCustomers"
                        @focus="showTouchKeyboard" :data-layout="KEYBOARD.KEYSETS.NORMAL" @blur="focus=false;">

          </v-text-field>
        </template>
        <v-card max-height="300" width="100%" max-width="100%" :loading="loadingCustomers">
          <v-list dense>
            <template v-if="showCustomers && showCustomers.length>0">
              <v-list-item dense v-for="(customer,i)  in showCustomers" :key="i" @click="setCustomer(customer)" class="border-top" >
                <v-list-item-content>
                  <v-list-item-title>
                    {{customer.full_name}}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{customer.company}}
                  </v-list-item-subtitle>
                </v-list-item-content>

              </v-list-item>
            </template>
            <template v-else>
              <v-list-item >
                <v-list-item-content>
                  {{this.$t('generic.lang_noDataToDisplay')}}
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
        </v-card>
      </v-menu>

      <br>

      <v-autocomplete
        v-if="this.selectedCustomer && this.selectedCustomer.hasBillingContact"
        v-model="model"
        :items="billingContacts"
        :disabled="loading"
        :label="$t('customers.lang_cust_select_billing_adress')"
        class="mx-1"
        item-text="id"
        item-value="id"
        outlined
        dense
      >
        <template v-slot:selection="data">
          {{ data.item.full_name }}
        </template>

        <template v-slot:item="data">
          <template>
            <v-list-item-content @click="setContact(data.item)">
              <v-list-item-title v-text="data.item.full_name"/>
              <v-list-item-subtitle v-text="data.item.streetNumber + ' ' + data.item.street"/>
              <v-list-item-subtitle v-text="data.item.city ? data.item.city + ', ' + data.item.zip : data.item.zip"/>
            </v-list-item-content>
          </template>
        </template>
      </v-autocomplete>
    </div>

    <v-form v-if="this.showForm" ref="createCustomerForm" v-model="valid">
      <v-container>
        <v-row>
          <!-- Vorname -->
          <v-col cols="6">
            <v-text-field v-model="firstName"
                          :data-layout="KEYBOARD.KEYSETS.NORMAL"
                          :disabled="loading"
                          :label="$t('customers.lang_cust_prename')"
                          :loading="loading"
                          :rules="firstNameRules"
                          dense

                          required
                          type="text"
                          @focus="showTouchKeyboard"
                          outlined
                          hide-details
            />
          </v-col>

          <!-- Nachname -->
          <v-col cols="6">
            <v-text-field v-model="lastName"
                          :data-layout="KEYBOARD.KEYSETS.NORMAL"
                          :disabled="loading"
                          :label="$t('customers.lang_cust_name')"
                          :loading="loading"
                          :rules="lastNameRules"
                          dense
                          required
                          type="text"
                          @focus="showTouchKeyboard"
                          outlined
                          hide-details
            />
          </v-col>

          <v-col cols="12" sm="12" md="6">
            <v-text-field v-model="company"
                          :data-layout="KEYBOARD.KEYSETS.NORMAL"
                          :disabled="loading"
                          :label="$t('generic.lang_company')"
                          :loading="loading"
                          dense
                          required
                          type="text"
                          @focus="showTouchKeyboard"
                          outlined
                          hide-details
            />
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <v-text-field v-model="number"
                          :data-layout="KEYBOARD.KEYSETS.NORMAL"
                          :disabled="loading"
                          :label="$t('generic.lang_telefone')"
                          :loading="loading"
                          dense
                          required
                          type="number"
                          @focus="showTouchKeyboard"
                          outlined
                          hide-details
            />
          </v-col>
          <!-- E-Mail -->
          <v-col cols="12">
            <v-text-field v-model="email"
                          :data-layout="KEYBOARD.KEYSETS.NORMAL"
                          :disabled="loading"
                          :label="$t('generic.lang_emailAdresse')"
                          :loading="loading"
                          dense

                          required
                          type="email"
                          @focus="showTouchKeyboard"
                          outlined
                          hide-details
            />
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <v-text-field v-model="street"
                          :data-layout="KEYBOARD.KEYSETS.NORMAL"
                          :disabled="loading"
                          :label="$t('generic.lang_street')"
                          :loading="loading"
                          dense

                          required
                          type="text"
                          @focus="showTouchKeyboard"
                          outlined
                          hide-details
            />
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <v-text-field v-model="streetno"
                          :data-layout="KEYBOARD.KEYSETS.NORMAL"
                          :disabled="loading"
                          :label="$t('generic.lang_street')+' '+$t('customers.lang_number')"
                          :loading="loading"
                          dense

                          required
                          type="text"
                          @focus="showTouchKeyboard"
                          outlined
                          hide-details
            />
          </v-col>
            <v-col cols="7">
              <v-text-field v-model="city"
                            :data-layout="KEYBOARD.KEYSETS.NORMAL"
                            :disabled="loading"
                            :label="$t('settings.lang_city')"
                            :loading="loading"
                            dense

                            required
                            type="text"
                            @focus="showTouchKeyboard"
                            outlined
                            hide-details
              />
            </v-col>
            <v-col cols="5">
              <v-text-field v-model="zip"
                            :data-layout="KEYBOARD.KEYSETS.NUMERIC"
                            :disabled="loading"
                            :label="$t('customers.lang_cust_zip')"
                            :loading="loading"
                            dense
                            required
                            type="text"
                            @focus="showTouchKeyboard"
                            outlined
                            hide-details
              />
            </v-col>
          </v-row>

        </v-container>
      </v-form>
    </v-card-text>
    <v-card-actions >
      <v-btn @click="closeDialog" color="error" text class="">{{$t('generic.lang_cancel')}}</v-btn>

      <v-btn @click="createNewCustomer()" :disabled="!valid||loading || loadingCustomers || !showForm"
             color="primary" text v-if="this.$store.getters['permissions/checkPermission']('create_cust')"
             class=" text-right">{{$t('customers.lang_create_NewCust')}}
      </v-btn>
      <v-btn :disabled="!selectedCustomer || (this.selectedCustomer.hasBillingContact && !selectedContact)" :loading="loading || loadingCustomers"

             color="success" @click="selectCustomer" >{{$t('generic.lang_selectCustomer')}}
      </v-btn>
    </v-card-actions>
    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard" style="z-index: 299 !important;">
      <vue-touch-keyboard v-if="touchKeyboard.visible" id="onScreenKeyboard"
                          :accept="hideTouchKeyboard"
                          :cancel="hideTouchKeyboard"
                          :defaultKeySet="touchKeyboard.keySet"
                          :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                          :options="touchKeyboard.options" class="internalWidthExpanded"/>
    </div>
  </v-card>
</v-dialog>
</template>

<script>
import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";
import KeyboardMixIns from "../../mixins/KeyboardMixIns";

export default {
  name: "CustomerSearchComponent",
  mixins: [KeyboardMixIns],
  props:{
    showDialog:Boolean,
    customerID:{
      required:false
    },
    customer:{
      required:false
    }
  },
  data(){
    return{
      focus: false,
      loading: false,
      loadingCustomers:false,
      searchInput:'',
      valid: false,
      showForm: false,
      model: null,
      billingContacts: [],
      selectedContact: null,
      selectedCustomer:null,
      firstName: "",
      firstNameRules: [
        v => !!v || this.$t('generic.lang_requiredField'),
      ],
      lastName: "",
      lastNameRules: [
        v => !!v || this.$t('generic.lang_requiredField'),
      ],
      email: "",
      number:'',
      street: "",
      streetno: "",
      zip: "",
      city: "",
      company: "",
      awaitingSearch: null,
      showCustomers: [],
    }
  },
  watch:{
    showDialog(val){
      if(!val){
        this.focus=false;
      }
    },
    customer(val){
      if(val){
        //this.selectedCustomer=val;
        //this.loadCustomer();

        this.selectedCustomer = val;
        if (val.hasBillingContact) {
          this.loadBillingContacts();
          this.setContact(val);
        } else {
          this.loadCustomer();
        }
      }
    },
    searchInput(val) {

      clearTimeout(this.awaitingSearch);

      this.awaitingSearch = setTimeout(() => {
        val && val !== this.select && this.loadCustomers()
      }, 1000);
    },
  },

  methods:{
    loadCustomers() {
      this.loadingCustomers = true;

      //AXIOS CALL
      this.axios.get(ENDPOINTS.ERP.CUSTOMER.GET, {
        params: {
          search:this.searchInput,
        }
      }).then((res) => {

        if (res.status === 200) {
          if (res.data.results.length > 0) {
            this.showCustomers = res.data.results;
          } else {
            this.showCustomers = [];
          }
          this.focus=true;
        }
      }).catch(() => {

      }).finally(()=>{
        this.loadingCustomers = false;
      });
    },
    setCustomer(customer){
      this.selectedCustomer = customer;
      if (this.selectedCustomer.hasBillingContact) {
        this.selectedContact = null;

        this.model = null;
        this.firstName= "";
        this.lastName= "";
        this.email= "";
        this.street= "";
        this.number= "";
        this.zip= "";
        this.city= "";
        this.company= "";
        this.streetno= "";

        this.showForm = false;
        this.loadBillingContacts();
      } else {
        this.loadCustomer();
      }
    },
    loadCustomer(){
      if(this.selectedCustomer){
        this.showForm = true;

        this.firstName= this.selectedCustomer.first_name  ;
        this.lastName= this.selectedCustomer.last_name;
        this.email= this.selectedCustomer.email;
        this.street= this.selectedCustomer.street;
        this.number= this.selectedCustomer.mobile;
        this.zip= this.selectedCustomer.zip;
        this.city= this.selectedCustomer.city ;
        this.company= this.selectedCustomer.company ;
        this.streetno= this.selectedCustomer.streetNumber ;
      }
    },
    selectCustomer(){
      if(this.selectedCustomer){
        this.focus=false;
        let customer={
          id:this.selectedCustomer.id,
          first_name:this.firstName,
          last_name:this.lastName,
          email:this.email,
          street:this.street,
          streetNumber:this.streetno,
          mobile:this.number,
          zip:this.zip,
          city:this.city,
          company:this.company,
          hasBillingContact:this.selectedCustomer.hasBillingContact,
          hasShippingContact:this.selectedCustomer.hasShippingContact,
        }

        this.$emit('selectCustomer',customer);
        this.$emit('close');
      }
    },
    loadBillingContacts() {
      this.loading = true;

      //AXIOS CALL
      this.axios
        .post(ENDPOINTS.CUSTOMERS.CUSTOMER.BILLINGCONTACTS, {
          parentCustomerId: this.selectedCustomer.id,
        })
        .then((res) => res)
        .then((res) => {
          if (res.status === 200) {
            if (res.data.results.length > 0) {
              this.billingContacts = res.data.results;
            } else {
              this.billingContacts = [];
            }
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    setContact(contact) {
      this.selectedContact = contact;
      this.loadContact();
    },
    loadContact() {
      if (this.selectedContact) {
        this.showForm = true;

        this.firstName= this.selectedContact.first_name  ;
        this.lastName= this.selectedContact.last_name;
        this.email= this.selectedContact.email;
        this.street= this.selectedContact.street;
        this.number= this.selectedContact.mobile;
        this.zip= this.selectedContact.zip;
        this.city= this.selectedContact.city ;
        this.company= this.selectedContact.company ;
        this.streetno= this.selectedContact.streetNumber ;
      }
    },
    createNewCustomer(){
      if (!this.$refs.createCustomerForm.validate()) {
        return;
      }

      this.loading = true;

      this.axios.post(ENDPOINTS.CUSTOMERS.CUSTOMER.CREATE, {
        first_name: this.firstName,
        last_name: this.lastName,
        email: this.email,
        street: this.street,
        street_no: this.streetno,
        mobil_private: this.number,
        zip: this.zip,
        city: this.city,
        company: this.company,
      }).then((res) => {
        this.loading = false;

        if (res.data.success) {
          let customer={
            id:res.data.customerID,
            first_name:this.firstName,
            last_name:this.lastName,
            email:this.email,
            street:this.street,
            streetNumber:this.streetno,
            mobile:this.number,
            zip:this.zip,
            city:this.city,
            company: this.company,
            text:this.firstName+' '+this.lastName,
            reservation_note:null,
            full_name:this.firstName+' '+this.lastName,
            hasBillingContact: false,
            hasShippingContact: false,
          }
          this.selectedCustomer=customer;
          this.$emit("selectCustomer", customer);
          this.$emit('close');
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch(() => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      }).finally(()=>{
        this.loading = false;
      })
    },
    closeDialog(){
      this.$emit('close');
    },
  },

  destroyed() {
    clearInterval(this.awaitingSearch);
  }
}
</script>

<style scoped>

</style>