<template>
  <v-dialog v-model="showDialog" persistent scrollable width="600">
    <v-card :loading="loading" :disabled="loading">
      <v-toolbar dense flat>
        <v-toolbar-title> {{ $t('settings.lang_createTemplate') }} </v-toolbar-title>

        <v-spacer/>

        <v-btn icon @click="closeDialog">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-alert :value="errorMessage.length > 0" class="text-center" text color="info"
                       v-html="errorMessage"></v-alert>

              <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL"
                            @focus="showTouchKeyboard" outlined :label="$t('erp.lang_TemplateName')" v-model="name"></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-radio-group v-model="templateType">
                <v-radio :label="$t('generic.moduleGastronomyName')" :value="1"></v-radio>
                <v-radio :label="$t('generic.moduleRetailName')" :value="2"></v-radio>
                <v-radio :label="$t('delivery.lang_delivery')" :value="3"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="6">
              <v-checkbox v-model="multiUser" :label="$t('settings.lang_enableMultipleUsers')">
              </v-checkbox>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-btn text color="primary" @click="closeDialog"> {{ $t('generic.lang_cancel') }} </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" :disabled="name.length < 3 || loading" :loading="loading" @click="createTemplate">
          {{ $t('generic.lang_next') }} 
        </v-btn>
      </v-card-actions>
      <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
        <vue-touch-keyboard id="onScreenKeyboard"
                            class="internalWidth"
                            :options="touchKeyboard.options"
                            :defaultKeySet="touchKeyboard.keySet"
                            v-if="touchKeyboard.visible"
                            :layout="touchKeyboard.layout"
                            :cancel="hidetouchKeyboard"
                            :accept="hidetouchKeyboard"
                            :input="touchKeyboard.input"/>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import {ENDPOINTS} from "../../../../config";
import KeyboardMixIns from "../../../../mixins/KeyboardMixIns";

export default {
  name: "POSGridTemplateCreateDialog",
  props: {
    showDialog: Boolean
  },
  mixins:[KeyboardMixIns],
  data() {
    return {
      name: "",
      templateType: 1,
      loading: false,
      errorMessage: "",
      multiUser: false,
    }
  },
  watch: {
    showDialog(val) {
      if (!val) {
        this.name = "";
        this.templateType = 1;
        this.errorMessage = "";
      }
    }
  },

  methods: {
    closeDialog() {
      this.$emit("close");
    },
    createTemplate() {
      this.loading = true;

      this.axios.post(ENDPOINTS.SETTINGS.POSLAYOUT.TEMPLATES.CREATE, {
        templateName: this.name,
        multiUser: this.multiUser,
        templateType: parseInt(this.templateType)
      }).then((res) => {
        if (res.data.success) {
          this.name = "";

          this.$router.push({
            name: 'settings.cashierSettings.layoutSettings.posGridTemplate', params: {
              id: res.data.templateID
            }
          })
        } else {
          this.errorMessage = res.data.msg;
        }

        this.loading = false;
      }).catch(() => {
        this.loading = false;
        this.errorMessage = this.$t('generic.lang_anErrorOccurredPLSTryAgain');
      })
    }
  }
}
</script>
