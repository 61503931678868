<template>
  <div>
    <div v-if="loading" style="text-align: center; padding-top: 20px; padding-bottom: 50px;">
      <v-progress-circular width="3" indeterminate color="primary"></v-progress-circular>
    </div>

    <v-simple-table v-if="!loading && cashiers.length > 0" class="table-striped">
      <template v-slot:default>
        <thead>
        <tr>
          <th class="text-left">{{ $t('generic.lang_kasse') }}</th>
          <th class="text-left">{{ $t('generic.moduleRetailName') }}</th>
          <th class="text-left">{{ $t('generic.moduleGastronomyName') }}</th>
          <th class="text-left">{{ $t('generic.lang_lieferdienst') }}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="cashier in cashiers" :key="cashier.id">
          <td>{{ $t('generic.lang_kasse') }} {{cashier.id}} <br/>({{ cashier.alias }})</td>
          <td>
            <v-select clearable :items="templatesRetail" v-model="selectedRetailTemplates[cashier.id]"
                      @change="assignTemplate($event, cashier.id)" style="margin-top: 20px;" outlined item-value="id"
                      item-text="name" :no-data-text="$t('generic.lang_noTemplateAvailable')"
                      :label="$t('generic.lang_chooseTemplate')"></v-select>
          </td>
          <td>
            <v-select :items="templatesGastro" v-model="selectedGastroTemplates[cashier.id]"
                      @change="assignTemplate($event, cashier.id)" style="margin-top: 20px;" outlined item-value="id"
                      item-text="name" :no-data-text="$t('generic.lang_noTemplateAvailable')"
                      :label="$t('generic.lang_chooseTemplate')"></v-select>
          </td>
          <td>
            <v-select :items="templatesDelivery" v-model="selectedDeliveryTemplates[cashier.id]"
                      @change="assignTemplate($event, cashier.id)" style="margin-top: 20px;" outlined item-value="id"
                      item-text="name" :no-data-text="$t('generic.lang_noTemplateAvailable')"
                      :label="$t('generic.lang_chooseTemplate')"></v-select>
          </td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import {ENDPOINTS} from "@/config";

export default {
  name: "POSGridTemplateAssign",

  data() {
    return {
      loading: true,
      cashiers: [],
      templatesGastro: [],
      templatesRetail: [],
      templatesDelivery: [],
      selectedRetailTemplates: [],
      selectedGastroTemplates: [],
      selectedDeliveryTemplates: []
    }
  },

  mounted() {
    this.getAssignments();
  },

  methods: {
    getAssignments() {
      this.loading = true;

      this.axios.post(ENDPOINTS.SETTINGS.POSLAYOUT.ASSIGNMENTS.GET).then((res) => {
        if (res.data.success) {
          this.cashiers = res.data.cashiers;
          this.templatesGastro = res.data.templatesGastro;
          this.templatesRetail = res.data.templatesRetail;
          this.templatesDelivery = res.data.templatesDelivery;
          this.selectedRetailTemplates = res.data.selectedRetailTemplates;
          this.selectedGastroTemplates = res.data.selectedGastroTemplates;
          this.selectedDeliveryTemplates = res.data.selectedDeliveryTemplates;

          this.loading = false;
        }
      }).catch(() => {
        this.loading = false;
      })
    },
    assignTemplate(templateID, cashierID) {
      //SEND API REQUEST TO SAVE ASSIGNMENT
      this.axios.post(ENDPOINTS.SETTINGS.POSLAYOUT.ASSIGNMENT.UPDATE, {
        templateID: templateID,
        cashierID: cashierID
      }).then((res) => {
        if (!res.data.success) {
          this.getAssignments();
        }
      }).catch(() => {
        this.getAssignments();
      });
    }
  }
}
</script>
